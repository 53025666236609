import React,{useState,useEffect} from 'react'
import DashboardLayout from '../components/DashboardLayout/DashboardLayout'
import { Select } from 'antd';
import { DatePicker, Space } from 'antd';
import {    AiOutlineFilter } from 'react-icons/ai'
import { HiOutlineUsers } from 'react-icons/hi'
import { AiOutlineInfo,AiOutlineExclamationCircle } from 'react-icons/ai'
import * as urls from "../Urls";
import DetailModal from '../components/DetailModal/DetailModal'
import axios from 'axios'
import MoonLoader from "react-spinners/MoonLoader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router-dom';

const TripLog = () =>{
    const[tripList,setTripList] = useState([])
    const[loading,setLoading] = useState(false)
    const[pageCount,setPageCount] = useState('')
    const[routepOptions,setRouteOptions] = useState([{value:'',label:''}])
    const[route,setRoute] = useState('')
    const[showTrip,setShowTrip] = useState(false)
    const[showFilter,setShowFilter] = useState(false)
    const[tripDetail,setTripDetail] = useState({img:'',code:'',route:'',bus:'',st:'',et:'',soc:'',sdc:'',sp:'',ep:''})
    const token = localStorage.getItem("busAdmin");
    const history = useHistory()
    const fetchTrip = (curentPage) =>{
        setLoading(true)
        axios.get(urls.tripListLog+'?is_active=true&page='+curentPage, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
            setTripList(response1.data.results)
            console.log(response1)
            setPageCount(response1.data.count/10)
            setLoading(false)
        })
        .catch((error) => {
          console.log(error);
        });

    }
    const fetchRoute = () =>{
        axios.get(urls.listRoutes, { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
            const newList = []
            for(let i=0;i<response.data.results.length;i++){
                newList.push({ value: response.data.results[i].id,label:  response.data.results[i].name,})
            }
            setRouteOptions(newList)
        })
        .catch((error) => {
          console.log(error);
        });

    }
    const filterByDate = (date) =>{
        setLoading(true)
        axios.get(urls.tripListLog+'?is_active=true&page=1&end='+date, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
            setLoading(false)
            setTripList(response1.data.results)
            setPageCount(response1.data.count/10)
            setLoading(false)
        })
        .catch((error) => {
          console.log(error);
        });

    }
    useEffect(()=>{
        fetchTrip('1')
        fetchRoute()

    },[])
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setRoute(value)
        setLoading(true)
        axios.get(urls.tripListLog+'?is_active=true&route_id='+value, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
            setTripList(response1.data.results)
            setPageCount(response1.data.count/10)
            setLoading(false)
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const viewHandler = (selItem) =>{
        setShowTrip(true)
        setTripDetail({code:'Trip#'+selItem.id,route:selItem.route_id.name,bus:selItem.vehicle_id.bus_no,soc:selItem.onboarded_count,
        sdc:selItem.deboarded_count,sp:selItem.start_locationtext,ep:selItem.end_locationtext,
        st:moment(selItem.start_time).format('MM/DD/YYYY  h:mm a'),et:moment(selItem.end_time).format('MM/DD/YYYY  h:mm a')})
    }
    const tripCloseHanlder = () =>{
        setShowTrip(false)
    }
    const onStartDateChange = (date, dateString) => {
        console.log(date, dateString);
        filterByDate(dateString)
    };
    const onEndDateChange = (date, dateString) => {
        filterByDate(dateString)
        console.log(date, dateString);
    };
    const viewStudentList = (tripId) =>{
        history.push({
            pathname:'/studentList',
            state:{
                tripId:tripId
            }
        })

    }
    const handlePageClick = (data) =>{
        if(route ===''){
            fetchTrip(data.selected+1)

        }
        else{
            axios.get(urls.tripList+'is_active=true&route_id='+route, { headers: { Authorization: "Bearer " + token } })
            .then((response1) => {
                setTripList(response1.data.results)
                setPageCount(response1.data.count/10)
                setLoading(false)
            })
            .catch((error) => {
              console.log(error);
            });
            
        }
       
       
    }
    const filterHandler = () =>{
        setShowFilter(!showFilter)

    }
      let trips;
    if(loading){
        trips = (
            <div
            className="d-flex justify-content-center"
            style={{
              paddingTop: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
             
            }}
          >
        <MoonLoader color="#0D2641"/>
        </div>
        )
    }
    else{
        trips =  <table className='table' style={{width:'100%',marginLeft:'0px'}}>
        <tr className='firstRow'>
            <th>Sl no.</th>
            <th>Trip Code</th>
            <th>Route</th>
            <th>Start Time</th>
            <th>End Date</th>
            <th>Students Onboarded</th>
            <th>Students Deboarded</th>
            <th>Action</th>
        </tr>
   
        {tripList.map((item,index)=>{
            return(
                <tr className='rowItems' key={index}>
                <td>{index+1}</td>
                <td>{'Trip:#'+item.id}</td>
                <td>{item.route_id.name}</td>
                <td>{moment(item.start_time).format('MM/DD/YYYY  h:mm a')}</td>
                <td>{moment(item.end_time).format('MM/DD/YYYY  h:mm a')}</td>
                <td>{item.onboarded_count}</td>
                <td>{item.deboarded_count}</td>
                <td><HiOutlineUsers color='#0D77BD' size={35} style={{cursor:'pointer'}} onClick={()=>viewStudentList(item.id)}/><AiOutlineInfo color='#E0E2E7' size={35}/>
                <AiOutlineExclamationCircle color='#33BBE0' size={35} onClick={()=>viewHandler(item)} style={{cursor:'pointer'}}/></td>
            </tr>
            )
        })}
       
     
    </table>
    }
    return(
            <DashboardLayout selectKey={['3']}>
                 < DetailModal showDet={showTrip} detHandleClose={tripCloseHanlder}>
                               
                               <div className='modalCont'>
                                   <div  style={{paddingBottom:'15px'}}>
                                   <h5>Trip code</h5>
                                   <span>{tripDetail.code}</span>

                                   </div>
                                   <div  style={{paddingBottom:'15px'}}>
                                   <h5>Route</h5>
                                   <span>{tripDetail.route}</span>

                                   </div>
                                   <div  style={{paddingBottom:'15px'}}>
                                   <h5>Students Onboarded</h5>
                                   <span>{tripDetail.soc}</span>

                                   </div>
                                   <div style={{paddingBottom:'15px'}}>
                                   <h5>Students Deboarded</h5>
                                   <span>{tripDetail.sdc}</span>

                                   </div>
                                   <div style={{paddingBottom:'15px'}}>
                                   <h5>Start Time</h5>
                                   <span>{tripDetail.st}</span>

                                   </div>
                                   <div style={{paddingBottom:'15px'}}>
                                   <h5>End time</h5>
                                   <span>{tripDetail.et}</span>

                                   </div>
                                   <div style={{paddingBottom:'15px'}}>
                                       <h5>Start point</h5>
                                       <span>{tripDetail.sp}</span>
                                   </div>
                                   <div style={{paddingBottom:'15px'}}>
                                           <h5>End point </h5>
                                           <span>{tripDetail.ep} </span>
                                   </div>
                                
                                   {/* <div >
                                           <h5>Route </h5>
                                           <span>{busDetail.route}</span>
                                   </div> */}
                                  
                                   
                               </div>
               </DetailModal>
                <div className='Dashboard'>
                    <h3 className='Head'>Trips Log</h3>
                    <div className='selectBox'>
                    <div className='d-flex'>
                       {showFilter?
                       <>
                            <div>
                                <h5 className='selectHead'>Route</h5>
                                    <Select
                                           value={route}
                                            style={{
                                                width: 120,
                                            
                                            }}
                                            onChange={handleChange}
                                            options={routepOptions}
                                    />
                            </div>
                            <div style={{marginLeft:'20px'}}>
                                <h5 className='selectHead'>Start date</h5>
                                <DatePicker onChange={onStartDateChange} />
                            </div>
                            <div style={{marginLeft:'20px'}}>
                                <h5 className='selectHead'>End date</h5>
                                <DatePicker onChange={onEndDateChange} />
                            </div>
                            </>
                          
                       :null} 
                        </div>

                        <div className='d-flex'>
                            <div className='filterBox' onClick={filterHandler}>< AiOutlineFilter color='#0D77BD' size={20}/> Filter</div>
                            {/* <div className='exportBox'> Export</div> */}
                         </div>
                       
                        </div>
                        {trips}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />

                   
                </div>
            </DashboardLayout>

    )
}
export default TripLog