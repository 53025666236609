import React, { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { BiEdit } from "react-icons/bi";
import CreateStaff from "../components/DetailModal/CreateStaff";
import MoonLoader from "react-spinners/MoonLoader";
import ReactFlagsSelect from "react-flags-select";
import * as urls from "../Urls";
import axios from "axios";
import * as country from "../countryList";
import Switch from "react-switch";
import ReactPaginate from "react-paginate";
import ChangeRole from "../components/DetailModal/ChangeRole";

const AdminStaff = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminStafList, setAdminStafList] = useState([]);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");
  const [editShow,setEditShow] = useState(false)
  const [roleNameError, setRoleNameError] = useState("");
  const [selected, setSelected] = useState("QA");
  const [changeShow,setChangeShow] = useState(false)
  const[staffId,setStaffId] = useState('')
  const[pageCount,setPageCount] = useState('')
  const [status,setStatus] = useState(true)
 
  const token = localStorage.getItem("busAdmin");
  const handleClose = () => {
    setShow(false);
    setName("");
    setRoleName("");
    setContact("");
  };
  const fetchAdminStaff = (currentPage) => {
    setLoading(true)
    axios
      .get(urls.adminStaf+'?page=' + currentPage, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response2) => {
        setAdminStafList(response2.data.results);
        setLoading(false)
        setPageCount(response2.data.count/10)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchRole = () => {
    axios
      .get(urls.entireRoleList, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response2) => {
        setRoleList(response2.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchAdminStaff("1");
    fetchRole();
  }, []);
  const handlePageClick = (data) =>{
    fetchAdminStaff(data.selected+1)
    
  }
  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };
  const contactChangeHandler = (e) => {
    setContact(e.target.value);
  };
  const roleChangeHandler = (e) => {
    setRoleName(e.target.value);
  };
  const handleChange = (staffId,staffStatus) => {
    setChangeShow(true)
    setStaffId(staffId)
    setStatus(staffStatus)

  };
  const changeCloseHanlder = () =>{
    setChangeShow(false)
  }
  const editShowHandler = (selItem) =>{
    setEditShow(true)
    setName(selItem.name)
    setStaffId(selItem.id)
    setContact(selItem.phone_number)
    setRoleName(selItem.role_id.id)
     let selObj = country.arrayCountryList.find(
        (item) => item.code === selItem.phone_code
      );
      setSelected(selObj.name)
  }
  const editHandleClose = () =>{
    setEditShow(false)
    setName('')
    setContact('')
    setRoleName('')
    setSelected('QA')
    setNameError('')
    setContactError('')
    setRoleNameError('')
  }
  
  const editStatusHandler = () =>{
    const body = {
        is_active:!status
    }
    axios.patch(urls.adminStaf+'/'+staffId,body, { headers: { Authorization: "Bearer " + token } })
    .then((response2) => {
        console.log(response2);
        setChangeShow(false)
        setStaffId('')
        fetchAdminStaff('1')
        
    
    })
    .catch((error) => {
        console.log(error);
    });

  }
  const validate = () => {
    let nameError;
    let contactError;
    let roleNameError;
    if (!name) {
      nameError = "This field is required";
      setNameError(nameError);
    }
    if (!contact) {
      contactError = "This field is required";
      setContactError(contactError);
    }
    if (!roleName) {
      roleNameError = "This field is required";
      setRoleNameError(roleNameError);
    }
    if (nameError || contactError || roleNameError) {
      setNameError(nameError);
      setContactError(contactError);
      setRoleNameError(roleNameError);

      return false;
    }
    return true;
  };
  const createADminStaff = (e) => {
    e.preventDefault();
    const isValidate = validate();
    if (isValidate) {
      setNameError("");
      setContactError("");
      setRoleNameError("");
      let selObj = country.arrayCountryList.find(
        (item) => item.name === selected
      );
      const body = {
        name: name,
        role_id: roleName,
        phone_code: selObj.code,
        phone_number: contact,
      };
      axios
        .post(urls.adminStaf, body, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response2) => {
          setShow(false);
          fetchAdminStaff("1");
        })
        .catch((error) => {
          console.log(error);
        });

     
    }
  };
  const editADminStaff = (e) =>{
    e.preventDefault();
    const isValidate = validate();
    if (isValidate) {
      setNameError("");
      setContactError("");
      setRoleNameError("");
      let selObj = country.arrayCountryList.find(
        (item) => item.name === selected
      );
      const body = {
        name: name,
        role_id: roleName,
        phone_code: selObj.code,
        phone_number: contact,
      };
      console.log(body)
      axios
        .patch(urls.adminStaf+'/'+staffId, body, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response3) => {
            setEditShow(false)
            fetchAdminStaff('1')
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }
  const addStafHandler = () => {
    setShow(true);
  };
 
  let adminStafLists;
  if (loading) {
    adminStafLists = (
      <div
        className="d-flex justify-content-center"
        style={{
          paddingTop: "250px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MoonLoader color="#0D2641" />
      </div>
    );
  } else {
    adminStafLists = (
      <table className="table" style={{ width: "100%", marginLeft: "0px" }}>
        <thead></thead>
        <tbody>
          <tr className="firstRow">
            <th>Sl no.</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Role</th>
            <th>Status</th>
            <th className="action">Action</th>
          </tr>
          {adminStafList.map((item, index) => {
            return (
              <tr className="rowItems" key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.phone_code + item.phone_number}</td>
                <td>{item.role_id.role_name}</td>
                <td>
                 <Switch
                    onChange={() => handleChange(item.id, item.is_active)}
                    checked={item.is_active}
                    checkedIcon={false}
                    uncheckedIcon={true}
                    height={20}
                    width={40}
                  />
                </td>
                <td className="action">
                  <BiEdit
                    color="#0D77BD"
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={()=>editShowHandler(item)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return (
    <DashboardLayout selectKey={["8"]}>
          <ChangeRole
            title=""
            body={"Are you sure you want to "+`${!status?"enable":"disable"}`+ " this Route?"}
            shows={changeShow}
            handleClose={changeCloseHanlder}
            okHandler={editStatusHandler}
        />
      <CreateStaff title="Create Admin Staff" onHide={handleClose} show={show}>
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Name</label>
              <br />
              <input
                type="text"
                className="inputText"
                placeholder="Enter your name"
                onChange={nameChangeHandler}
                value={name}
              />
              <div className="ErrorMsg">{nameError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Contact number</label>
              <br />
              <div className="d-flex">
                <ReactFlagsSelect
                  selected={selected}
                  countries={country.countryList}
                  customLabels={country.countryCode}
                  className="menu-flags"
                  selectedSize={14}
                  fullWidth={false}
                  onSelect={(code) => setSelected(code)}
                />

                <input
                  type="text"
                  className="inputText"
                  onChange={contactChangeHandler}
                  value={contact}
                />
              </div>

              <div className="ErrorMsg">{contactError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Role</label>
              <br />
              <select className="selectText" onChange={roleChangeHandler}>
                <option>Select Role</option>
                {roleList.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>{item.role_name}</option>
                  );
                })}
              </select>
              <div className="ErrorMsg">{roleNameError}</div>
            </div>
          </div>

          <div className="submit" onClick={createADminStaff}>
            SUBMIT
          </div>
        </form>
      </CreateStaff>
      <CreateStaff title="Edit Admin Staff" onHide={editHandleClose} show={editShow}>
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Name</label>
              <br />
              <input
                type="text"
                className="inputText"
                placeholder="Enter your name"
                onChange={nameChangeHandler}
                value={name}
              />
              <div className="ErrorMsg">{nameError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Contact number</label>
              <br />
              <div className="d-flex">
                <ReactFlagsSelect
                  selected={selected}
                  countries={country.countryList}
                  customLabels={country.countryCode}
                  className="menu-flags"
                  selectedSize={14}
                  fullWidth={false}
                  onSelect={(code) => setSelected(code)}
                />

                <input
                  type="text"
                  className="inputText"
                  onChange={contactChangeHandler}
                  value={contact}
                />
              </div>

              <div className="ErrorMsg">{contactError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Role</label>
              <br />
              <select className="selectText" onChange={roleChangeHandler}>
                <option>Select Role</option>
                {roleList.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.role_name}
                    </option>
                  );
                })}
              </select>
              <div className="ErrorMsg">{roleNameError}</div>
            </div>
          </div>

          <div className="submit" onClick={editADminStaff}>
            SUBMIT
          </div>
        </form>
      </CreateStaff>
      <div className="Dashboard">
        <div className="selectBox">
          <h3 className="Head">Admin Staff</h3>
          <div className="addStaff" onClick={addStafHandler}>
            ADD
          </div>
        </div>
        {adminStafLists}
        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />
      </div>
    </DashboardLayout>
  );
};
export default AdminStaff;
