import React, { useState } from 'react';
import Classes from './DashboardLayout.module.css'
// import { BsCalendar3,BsSearch } from 'react-icons/bs';
// import { Input, Space } from 'antd';
// import Profile from '../../assets/images/profile.png'
// import {AiOutlineDown} from 'react-icons/ai'
import {  UserOutlined, } from '@ant-design/icons';
import { RxDashboard } from 'react-icons/rx'
import {  MdTrackChanges,MdOutlineLibraryBooks } from 'react-icons/md'
import {  SlBag } from 'react-icons/sl'
import {Layout, Menu, theme } from 'antd';
import { useHistory } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
    getItem('Dashboard', '1', <RxDashboard />),
    getItem('Tracking', '2', <MdTrackChanges />),
    getItem('Trips Log', '3', <MdOutlineLibraryBooks />,),
    getItem('Users', 'sub2', <UserOutlined/>, [getItem('Bus Staff', '6'), getItem('Admin Staff', '8')]),
    getItem('Masters', 'sub3', <SlBag />, [getItem('Students', '9'), getItem('Vehicles', '10'),getItem('Class', '11'),getItem('Route', '12'),getItem('Bus Message', '13'),getItem('Roles', '14'),getItem('Vendor', '15')]),
  ];
const DashboardLayout = (props) =>{
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(false);
    const {
      token: { colorBgContainer },
    } = theme.useToken();
    const menuClickHandler = (e) =>{
      if(e.key=== '1'){
            history.push('/track')
          
      }else if(e.key === '2'){
            history.push('/track')
           
      }else if(e.key === '3'){
            history.push('/triplog')
      }else if(e.key === '6'){
      history.push('/busstaff')
      }
      else if(e.key === '8'){
        history.push('/adminStaff')
      }
      else if(e.key === '9'){
        history.push('/masterStudentList')
      }
      else if(e.key === '10'){
        history.push('/masterBusList')
      }
      else if(e.key === '11'){
        history.push('/classList')
      }
      else if(e.key === '12'){
        history.push('/routeList')
      }
      else if(e.key === '13'){
        history.push('/notification')
      }
      else if(e.key === '14'){
        history.push('/roles')
      }
      else if(e.key === '15'){
        history.push('/vendor')
      }
    }
    return(
     <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={300} style={{background:'#0D2641'}}>
                    <div
                        style={{
                        height: 32,
                        background: '#0D2641',
                        }}
                        className={Classes.Logo}
                    >LOGO</div>
                    <Menu  selectedKeys={props.selectKey}  mode="inline" items={items}  
                    style={{background:'#0D2641',color:'#FFFFFF',fontSize:'16px'}} onClick={menuClickHandler}/>
             </Sider>
            <Layout className="site-layout" >
                  <Header
                    style={{
                      padding: 0,
                      background: '#F9F9F9',
                    }}
                  >
                    <div className={Classes.Header}>
                        {/* <div className={Classes.Calender}>
                            <BsCalendar3 size={16} color='#000000'/>
                            <span className={Classes.Date} >11th May</span>
                        </div> */}
                        <div className={Classes.Searchprof}>
                            {/* <div className={Classes.Search}>
                                <Space direction="vertical" style={{ width: '400px' }}>
                                    <Input  prefix={<BsSearch  size={15} color='#7E7E7E'/>} placeholder="Search by Bus number, Students name..." />
                                </Space>
                            </div> */}
                            {/* <div className={Classes.ProfileDescrp}><img src={Profile} alt='profile'/>
                                <p className={Classes.profName}>Tina Roy</p>
                                <AiOutlineDown color='#000000' size={15} style={{marginRight:'10px'}}/>
                            </div> */}
                        </div>
                    </div>
                  </Header>
                <Content
                  style={{ background:'#EBEBEB' }}
                >
                          {props.children}
                </Content>
                <Footer
                  style={{
                    textAlign: 'center',
                  }}
                >
                        {/* Ant Design ©2018 Created by Ant UED */}
                </Footer>
            </Layout>
    </Layout>

    )
}
export default DashboardLayout