import React,{useState,useEffect} from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
// import {RiDeleteBin6Line} from 'react-icons/ri'
import {BiEdit} from 'react-icons/bi'
import MoonLoader from "react-spinners/MoonLoader";
// import {TbMinusVertical} from 'react-icons/tb'
import CreateStaff from '../components/DetailModal/CreateStaff'
import ReactPaginate from "react-paginate";
import * as urls from "../Urls";
import axios from 'axios'
import Switch from "react-switch";
import ChangeRole from "../components/DetailModal/ChangeRole";



export default function MastersRouteList() {
    const[routeShow,setRouteShow] = useState(false)
    const[loading,setLoading] = useState(false)
    const[routeList,setRouteList] = useState([])
    const[routeName,setRouteName] = useState('')
    const[routeNameError,setRouteNameError] = useState('')
    const[pageCount,setPageCount] = useState('')
    const[routeEditShow,setRouteEditShow] = useState(false)
    const[changeShow,setChangeShow] =useState(false)
    const[routeId,setRouteId] = useState('')
    const[status,setStatus] = useState(true)
    const token = localStorage.getItem("busAdmin");
    const fetchRoute = (cuurentPage) =>{
        setLoading(true)
        axios.get(urls.listRoute+cuurentPage, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
             setLoading(false);
             console.log(response1);
             setRouteList(response1.data.results)
             setPageCount(response1.data.count/10)
          })
          .catch((error) => {
            console.log(error);
          });
  
      }
      useEffect(()=>{
        fetchRoute('1')

      },[])
    const handleClose = () =>{
        setRouteShow(false)

    }
    const handleChange = (routeId,routStatus) =>{
        setRouteId(routeId)
        setStatus(routStatus)
        setChangeShow(true)

    }
    const changeCloseHanlder = () =>{
        setChangeShow(false)
        setRouteId('')
        setStatus(true)

    }
    const handleEditClose = () =>{
        setRouteEditShow(false)
        setRouteId('')
    }
    const routeEditHandler = (selItem) =>{
        setRouteName(selItem.name)
        setRouteId(selItem.id)
        setRouteEditShow(true)

    }
    const addRouteHandler = () =>{
        setRouteShow(true)

    }
    const routeNameChangeHandler = (e) =>{
        setRouteName(e.target.value)

    }
    
    const validate = () =>{
        let routeNameError;
        if(!routeName){
            routeNameError = 'This field is required'
            setRouteNameError(routeNameError)
        }
        if(routeNameError ){
            setRouteNameError(routeNameError)
            return false
        }
        return true
    }
    const createarouteHandler = (e) =>{
        e.preventDefault()
        const isValidate = validate()
        if(isValidate){
            setRouteNameError('')
                const body = {
                    "name":routeName
                }
                axios.post(urls.routeUrl,body, { headers: { Authorization: "Bearer " + token } })
                .then((response2) => {
                    setRouteShow(false)
                    fetchRoute('1')
                    setRouteName('')
                    console.log(response2);
                
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }
    const updateRouteHandler = () =>{
        const body = {
            name:routeName
        }
     
        axios.patch(urls.routeUrl+'/'+routeId,body, { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
            setRouteEditShow(false)
            setRouteId('')
            fetchRoute('1')
            
        
        })
        .catch((error) => {
            console.log(error);
        });

    }
    const editStatusHandler = () =>{
        const body = {
            is_active:!status
        }
     
        axios.patch(urls.routeUrl+'/'+routeId,body, { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
            console.log(response2);
            setChangeShow(false)
            setRouteId('')
            fetchRoute('1')
            
        
        })
        .catch((error) => {
            console.log(error);
        });
    }
    const handlePageClick = (data) =>{
        fetchRoute(data.selected+1)
        console.log(data.selected+1);
      }
    let routes;
    if(loading){
        routes = (
            <div
            className="d-flex justify-content-center"
            style={{
              paddingTop: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
             
            }}
          >
        <MoonLoader color="#0D2641"/>
        </div>
        )
    }
    else{
        routes =   <table className="table" style={{width:'100%',marginLeft:'0px'}}>
        <thead></thead>
        <tbody>
        <tr className="firstRow ">
                <th>Route </th>
                <th>Status</th>
                <th className="action">Action</th>
        </tr>
        {routeList.map((item,index)=>{
            return(

                <tr className="rowItems" key={index}>
                <td>{item.name}</td>
                <td> <Switch
                    onChange={()=>handleChange(item.id,item.is_active)}
                    checked={item.is_active}
                    checkedIcon={false}
                    uncheckedIcon={true}
                    height={20}
                    width={40}
                  /></td>
                <td className="action">
                    <BiEdit color="#0D77BD" size={25} style={{cursor:'pointer'}} onClick={()=>routeEditHandler(item)}/>
                  
                </td>
            </tr>
            )
        })}
        </tbody>
        <tfoot></tfoot>
        </table>
        
    }
  return (
    <DashboardLayout selectKey={["12"]}>
          <ChangeRole
            title=""
            body={"Are you sure you want to "+`${!status?"enable":"disable"}`+ " this Route?"}
            shows={changeShow}
            handleClose={changeCloseHanlder}
            okHandler={editStatusHandler}
        />
         <CreateStaff title='Create Route'  onHide={handleClose} show={routeShow}>
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Route</label><br/>
                        <input type="text" className='inputText' value={routeName} onChange={routeNameChangeHandler}/>
                        <div className="ErrorMsg">{routeNameError}</div>
                    </div>
                </div>
               
                <div className='submit' onClick={createarouteHandler}>SUBMIT</div>
            </form>
        </CreateStaff>
        <CreateStaff title='Edit Route'  onHide={handleEditClose} show={routeEditShow}>
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Route</label><br/>
                        <input type="text" className='inputText' value={routeName} onChange={routeNameChangeHandler}/>
                        <div className="ErrorMsg">{routeNameError}</div>
                    </div>
                </div>
               
                <div className='submit' onClick={updateRouteHandler}>SUBMIT</div>
            </form>
        </CreateStaff>
        <div className="Dashboard">
            <div className="selectBox">
                <h3 className="Head">Route</h3>
                <div className="addStaff" onClick={addRouteHandler}> ADD</div>
            </div>
                    {routes}
                    <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />
               
               
        </div>
    </DashboardLayout>
  );
}
