import React, { useState, useEffect,useRef} from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { AiOutlineArrowLeft } from "react-icons/ai";
import QRCode from "react-qr-code";
import ChangeRole from "../components/DetailModal/ChangeRole";
import ReactToPrint from "react-to-print-advanced";
import moment from "moment";

class ComponentToPrint extends React.Component {
    render() {
      return (
     
    <div className="row nopadmar">
        <div className="col-md-2 studentCard">
                        <div className="blackBox"></div>
                         <h3>LOGO</h3>
                     <div className="innerCard">
                       
                        <div className=" profileId">
                      
                                <div className="d-flex justify-content-center">
                             
                                <img
                                    src={this.props.image}
                                    alt="profile"
                                    width="80px"
                                    height="80px"
                                    className="studnt"
                                />
                                
                                </div>
                              
                                <h5
                                className=" d-flex justify-content-center"
                                style={{ marginLeft: "-25px" }}
                                >
                                     
                                {this.props.name}
                                </h5>
                                <h5
                                className=" d-flex justify-content-center"
                                style={{ marginLeft: "-25px" }}
                                >
                                {this.props.admnNo}
                                </h5>
                            <div className="d-flex ">
                                <div >
                                    <h5 style={styles.nameDet}>Phone number:</h5>
                                    </div>
                                <div >
                                    <h5 style={styles.nameVal}>{this.props.contct}</h5>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="nameDet">
                                    <h5 >Route:</h5>
                                </div>
                                <div className="nameVal">
                                    <h5 >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.route}</h5>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="nameDet">
                                    <h5 >Parent name: </h5>
                                </div>
                                <div className="nameVal">
                                    <h5 >&nbsp;&nbsp;&nbsp;{this.props.parName}</h5>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="nameDet">
                                    <h5 >Blood group:</h5>
                                </div>
                                <div className="nameVal">
                                    <h5 >&nbsp;&nbsp;&nbsp;{this.props.bldGrp}</h5>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="nameDet">
                                    <h5 >Class:</h5>
                                </div>
                                <div className="nameVal">
                                    <h5 >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.clas}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                      <div className="blackBox"></div>
                </div>
                 <div className="col-md-2 studentCard" style={{ marginLeft: "25px" }}>
                    <div className="blackBox"></div>
                         <h3>LOGO</h3>
         
                            <div
                            style={{
                                height: "auto",
                                margin: "0 auto",
                                maxWidth: 100,
                                width: "100%",
                            }}
                            >
                                <QRCode
                                    size={300}
                                    style={{ height: "215px", maxWidth: "100%", width: "100%" }}
                                    value={JSON.stringify(this.props.id)}
                                    viewBox={`0 0 256 256`}
                                />
                         </div>
                            <div className="d-flex justify-content-center">
                                <p className="issue">
                                    Issued on:{" "}
                                    <span className="issueVal">
                                    {moment(this.props.date).format("MM/DD/YYYY")}
                                    </span>
                                </p>
                            </div>
                                <div className="d-flex justify-content-center">
                                    <p className="issue">
                                        Expires on: <span className="issueVal">28/04/2012</span>
                                    </p>
                                </div>
                                <div className="blackBox"></div>
                    </div>
                    
    </div>
    )
}
    }
  
const ViewId = (props) => {
    const componentRef = useRef();
  const [show, setShow] = useState(false);
  const [student, setStudent] = useState({
    name: "",
    clas: "",
    parName: "",
    img: "",
    bldGrp: "",
    admnNo: "",
    route: "",
    contct: "",
    date: "",
    id: "",
  });
  useEffect(() => {
    console.log(props.location.state.data);
    setStudent(props.location.state.data);
  }, []);
  const printConfirmHandelr = () => {
    setShow(true);
  };
  const confirmCloseHanlder = () => {
    setShow(false);
  };
  const goBackHandler = () => {
    props.history.goBack();
  };
  return (
    <DashboardLayout selectKey={["9"]}>
      <ChangeRole
        body="Are you sure wou want to print the ID Card?"
        shows={show}
        handleClose={confirmCloseHanlder}
      />
      <div
        className="goBack"
        onClick={goBackHandler}
        style={{ cursor: "pointer" }}
      >
        <AiOutlineArrowLeft size={20} color="#0D77BD" />
        &nbsp;&nbsp;Go back
      </div>
      <div className="idHead">Student Details</div>
        <div style={{ padding: "25px" }}>
        
      <ComponentToPrint ref={componentRef} image={"https://bus.goldzin.com/" + student.img}
       name={student.name}  admnNo={student.admnNo} contct={student.contct} route={student.route}
       parName={student.parName} bldGrp={student.bldGrp} clas={student.clas} id={student.id} date={student.date}/>
           
           <ReactToPrint
        trigger={() => <div className="submit">PRINT</div>}
        content={() => componentRef.current}
      />
             
            <div>
     
    

                   
            </div>
        </div>
    </DashboardLayout>
  );
};
const styles = {
    nameVal:{
        padding: '10px 0px 0px 20px',
        fontSize: '12px',
        color: '#0D77BD',
        fontFamily: 'gilroyReg'
      
      },
      nameDet:{
        padding: '10px 0px 0px 30px',
        fontSize: '12px',
        color: '#0D2641',
        fontFamily: 'gilroyMed'
      }

}
export default ViewId;
