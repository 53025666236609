import React from 'react'
import Classes from './DetailModal.module.css'
import Modal from 'react-bootstrap/Modal';
const DetailModal = (props) =>{
   
    return(
       <>
       <Modal show={props.showDet} onHide={props.detHandleClose} centered>
            <Modal.Header closeButton className={Classes.ModalHeader}>
                 <Modal.Title>View Details</Modal.Title>
            </Modal.Header>
             <Modal.Body>{props.children}</Modal.Body>
                <Modal.Footer></Modal.Footer>
      </Modal>
       </>
    )
}
export default DetailModal