import React from 'react'
import DashboardLayout from '../components/DashboardLayout/DashboardLayout'
const StudentHistory = () =>{
    return(
        <DashboardLayout>
             <div className='Dashboard'>
                <div className='selectBox'>
                    <h3 className='Head'>History</h3>
                   
                 </div>
                 <table className='table'>
                            <tr className='firstRow'>
                                <th>Date.</th>
                                <th>Name</th>
                                <th>Route</th>
                                <th>Pickup Time</th>
                                <th>Pickup Location</th>
                                <th>Drop Time</th>
                                <th>Drop Location</th>
                             
                            </tr>
                            <tr className='rowItems'>
                                <td>22/05/2022</td>
                                <td>Arshiya Ilaheen</td>
                                <td>2</td>
                                <td>07:33 AM</td>
                                <td>Wakhra Stadium</td>
                                <td>03:30 PM</td>
                                <td>IISJ School</td>
                              
                            </tr>
                            <tr className='rowItems'>
                                <td>22/05/2022</td>
                                <td>Arshiya Ilaheen</td>
                                <td>2</td>
                                <td>07:33 AM</td>
                                <td>Wakhra Stadium</td>
                                <td>03:30 PM</td>
                                <td>IISJ School</td>
                              
                            </tr>
                         
                        </table>
                    </div>

        </DashboardLayout>

    )
}
export default StudentHistory