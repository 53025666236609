import React from 'react'
import Classes from './CoutCard.module.css'
const CountCard = (props) =>{
    return(
        <div className={Classes.Card}>
            <div className={Classes.Cards}>
                <h5>{props.head}</h5>
                <div className={Classes.SmallCard}>
                    <h3>{props.count}</h3>
                    </div>
            </div>
          
           

        </div>

    )
}
export default CountCard