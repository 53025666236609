import { initializeApp } from "firebase/app";
import {getDatabase} from 'firebase/database'
function StartFirebase(){
    const firebaseConfig = {
        apiKey: "AIzaSyDSrS2irESe_F_Z1Loz4efc6tLHoxuFCT0",
        authDomain: "bustracker-1stop.firebaseapp.com",
        databaseURL: "https://bustracker-1stop-default-rtdb.firebaseio.com",
        projectId: "bustracker-1stop",
        storageBucket: "bustracker-1stop.appspot.com",
        messagingSenderId: "961180611295",
        appId: "1:961180611295:web:8d05c7a4031c20b1d82f6a"
      };
      const app = initializeApp(firebaseConfig)
      return getDatabase(app)
}
export default StartFirebase