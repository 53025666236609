import React from 'react'
import Classes from './DetailModal.module.css'
import Modal from 'react-bootstrap/Modal';

const CreateStaff = (props) =>{
 

    
    return(
        <Modal show={props.show} onHide={props.onHide} centered >
        <Modal.Header closeButton className={Classes.ModalHeader}>
             <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
         <Modal.Body className='modalbg'>{props.children}</Modal.Body>
            
  </Modal>

    )
}
export default CreateStaff