import React,{useState,useEffect} from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import MoonLoader from "react-spinners/MoonLoader";
import axios from 'axios'
import {BiEdit} from 'react-icons/bi'
import * as urls from '../Urls'
import Switch from "react-switch";
import CreateStaff from '../components/DetailModal/CreateStaff'
import ReactPaginate from "react-paginate";
import ChangeRole from "../components/DetailModal/ChangeRole";
const Vendor = () => {
    const[loading,setLoading] = useState(false)
    const[vendorList,setVendorList] = useState([])
    const[vendorShow,setVendorShow] = useState(false)
    const[vendorName,setVendorName] = useState('')
    const[nameError,setNameError] = useState('')
    const[pageCount,setPageCount] = useState('')
    const[status,setStatus] = useState(false)
    const[changeShow,setChangeShow] = useState(false)
    const[vendorEditShow,setVendorEditShow] = useState(false)
    const[vendorId,setVendorId] = useState('')
    const token = localStorage.getItem("busAdmin");
    const fetchVendor = (cuurentPage) =>{
        setLoading(true)
        axios.get(urls.vendorList+cuurentPage, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
             setLoading(false);
             console.log(response1);
             setVendorList(response1.data.results)
             setPageCount(response1.data.count/10)
          })
          .catch((error) => {
            console.log(error);
          });
  
      }
      useEffect(()=>{
        fetchVendor('1')

      },[])
    const addVendorHandler = () =>{
        setVendorShow(true)

    }
    const handleClose = () =>{
        setVendorShow(false)

    }
    const handleChange = (vendorId,vendStatus) =>{
        setStatus(vendStatus)
        setVendorId(vendorId)
        setChangeShow(true)

    }
    const changeCloseHanlder = ()=>{
        setChangeShow(false)
    }
    const handlePageClick = (data) =>{
        fetchVendor(data.selected+1)
        console.log(data.selected+1);
      }
    const editStatusHandler = () =>{
        const body = {
            is_active:!status
        }
        axios.patch(urls.createVendor+'/'+vendorId,body, { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
            console.log(response2);
            setChangeShow(false)
            setVendorId('')
            fetchVendor('1')
        })
        .catch((error) => {
            console.log(error);
        });

    }
    const validate = () =>{
        let nameError;
        if(!vendorName){
            nameError = 'This field is required'
            setNameError(nameError)
        }
        if(nameError ){
            setNameError(nameError)
            return false
        }
        return true
    }
    const vendorEditHandler = (selItem) =>{
        setVendorEditShow(true)
        setVendorName(selItem.name)
        setVendorId(selItem.id)
    }
    const handleEditClose = () =>{
        setVendorEditShow(false)

    }
    const nameChangeHandler = (e) =>{
        setVendorName(e.target.value)
    }
    const editVendorHandler= () =>{
        let body={name:vendorName}
        axios.patch(urls.createVendor+'/'+vendorId,body, { headers: { Authorization: "Bearer " + token } })
        .then((response3) => {
          
            setVendorEditShow(false)
            setVendorId('')
            fetchVendor('1')
        })
        .catch((error) => {
            console.log(error);
        });

    }
    const createVendorHandler = (e) =>{
        e.preventDefault()
        const isValidate = validate()
        if(isValidate){
            setNameError('')
           
                const body = {
                    "name":vendorName
                }
                axios.post(urls.createVendor,body, { headers: { Authorization: "Bearer " + token } })
                .then((response2) => {
                    setVendorShow(false)
                    fetchVendor('1')
                    setVendorName('')
                    console.log(response2);
                
                })
                .catch((error) => {
                    console.log(error);
                });
            }

    }

    let vendors;
    if(loading){
        vendors = (
            <div
            className="d-flex justify-content-center"
            style={{
              paddingTop: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
             
            }}
          >
        <MoonLoader color="#0D2641"/>
        </div>
        )
    }
    else{
        vendors =   <table className="table" style={{width:'100%',marginLeft:'0px'}}>
        <thead></thead>
        <tbody>
        <tr className="firstRow ">
                <th>Vendor </th>
                <th>Status</th>
                <th className="action">Action</th>
        </tr>
        {vendorList.map((item,index)=>{
            return(
            <tr className="rowItems" key={index}>
                <td>{item.name}</td>
                <td> <Switch
                    onChange={()=>handleChange(item.id,item.is_active)}
                    checked={item.is_active}
                    checkedIcon={false}
                    uncheckedIcon={true}
                    height={20}
                    width={40}
                  /></td>
                <td className="action">
                    <BiEdit color="#0D77BD" size={25} style={{cursor:'pointer'}} onClick={()=>vendorEditHandler(item)}/>
                  
                </td>
            </tr>
            )
        })}
        </tbody>
        <tfoot></tfoot>
        </table>
        
    }
  return (
    <DashboardLayout selectKey={["15"]}>
         <ChangeRole
            title=""
            body={"Are you sure you want to "+`${!status?"enable":"disable"}`+ " this Route?"}
            shows={changeShow}
            handleClose={changeCloseHanlder}
            okHandler={editStatusHandler}
        />
        <CreateStaff title='Create Vendor'  onHide={handleClose} show={vendorShow}>
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Name</label><br/>
                        <input type="text" className='inputText' value={vendorName} onChange={nameChangeHandler}/>
                        <div className="ErrorMsg">{nameError}</div>
                    </div>
                </div>
               
                <div className='submit' onClick={createVendorHandler}>SUBMIT</div>
            </form>
        </CreateStaff>
        <CreateStaff title='Edit Vendor'  onHide={handleEditClose} show={vendorEditShow}>
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Name</label><br/>
                        <input type="text" className='inputText' value={vendorName} onChange={nameChangeHandler}/>
                        <div className="ErrorMsg">{nameError}</div>
                    </div>
                </div>
               
                <div className='submit' onClick={editVendorHandler}>SUBMIT</div>
            </form>
        </CreateStaff>
      <div className="Dashboard">
        <div className="selectBox">
          <h3 className="Head">Vendor</h3>
          <div className="addStaff" onClick={addVendorHandler}>
            ADD
          </div>
        </div>
        {vendors}
        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />
      </div>
    </DashboardLayout>
  );
};
export default Vendor;
