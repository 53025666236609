// t server
export const server = 'https://bus.goldzin.com/'


//login
export const login = server + 'api/v1/token';
//roles
export const roles = server + 'api/v1/roles?page='
//trip list
export const tripList = server + 'api/v1/trips?is_active=true&page_size=1000'
//one trip details
export const tripListDet = server + 'api/v1/trips?trip_id='
//trps in a student
export const tripsBystudent = server + 'api/v1/trips/'
//list bus messages
export const listBusMessages = server + 'api/v1/bus_message?page='
//list classes
export const listClasses = server + 'api/v1/classes?is_active=true&page_size=1000'
//list routes
export const listRoutes = server + 'api/v1/routes?is_active=true&page_size=1000'
//entire vehicle list
export const listVehicles = server + 'api/v1/vehicles?is_active=true&page_size=1000'
//create student
export const createStudent = server + 'api/v1/students'
//student list
export const listStudent = server + 'api/v1/students?page='
//edit student
export const editStudent = server + 'api/v1/student/'
//edit roles
export const editRoles = server + 'api/v1/roles/'
//list class with pagination
export const listClas = server + 'api/v1/classes?page='
//create class
export const classUrl = server + 'api/v1/classes'
//list routes with pagination
export const listRoute = server + 'api/v1/routes?page='
//create class
export const routeUrl = server +  'api/v1/routes'
//list buses
export const buses = server + 'api/v1/vehicles?page='
//create bus
export const createVehicle = server + 'api/v1/vehicles'
//vender list
export const vendor = server + 'api/v1/vendors?is_active=true&page_size=1000'
//list vendors with page
export const vendorList = server + 'api/v1/vendors?page='
//create vendor
export const createVendor = server + 'api/v1/vendors'
//fetch admin staff
export const fetchAdminStaf = server + 'api/v1/adminstaffs?page='

//listing entireRole list
export const entireRoleList = server + 'api/v1/roles?is_active=true&page_size=1000'
//admin staff
export const adminStaf = server + 'api/v1/adminstaffs'
//fetch bus staff
export const busStaf = server + 'api/v1/busstaffs'
//list trips in triplog
export const tripListLog = server + 'api/v1/trips'
//image upload
export const proImg = server + '/api/v1/student/'




