import React from 'react'
import Classes from './AuthLayout.module.css'

export default function AuthLayout(props) {
  return (
    <div className='container-fluid'>
    <div className='row nopadmar'>
        <div className='col-md-6'>
            <div className={Classes.LayouBg}>
            {props.children}


            </div>
        </div>
        <div className='col-md-6'>
            <div className={Classes.LoginBg}>
              
            </div>
        </div>
      
    </div>
    </div>
  )
}
