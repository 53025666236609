import React from 'react'
import Classes from './LiveTrack.module.css'
const BusDetail = (props) =>{
    return(
        <div className={`${Classes.LiveBusCard}`} style={props.style}>
             <div className={Classes.TopHead}>
                <div className={Classes.busHead}>
                    <h3>{props.busNo}</h3>
                    <div className={Classes.border}></div>
                    <h3>{props.busName}</h3>
                </div>
                <div className={Classes.trackBox}>
                        <h5 className={Classes.Location}>Start Location</h5>
                        <span className={Classes.locationName}>{props.curntLoc}</span>
                        <h5 className={Classes.Location}>Route:</h5>
                        <span className={Classes.locationName}>{props.route}</span>
                        <h5 className={Classes.Location}>Driver</h5>
                        <span className={Classes.locationName}>{props.driver}</span>
                </div>
           
            </div>
        </div>

    )
}
export default BusDetail