import './App.css';
import { BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import DashBoard from './pages/Dashboard';
import Tracking from './pages/Tracking';
import TripLog from './pages/TripLog';
import BusStaff from './pages/BusStaff'
import AdminStaff from './pages/AdminStaff';
import StudentList from './pages/StudentList';
import MasterStudentList from './pages/MasterStudentList';
import StudentHistory from './pages/StudentHistory';
import MastersBuses from './pages/MastersBuses';
import ViewId from './pages/ViewId'
import MasterClasses from './pages/MastersClassList'
import MastersRouteList from './pages/MastersRouteList';
import MasterNotification from './pages/MasterNotification';
import MasterRoles from './pages/MasterRoles';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Verification from './pages/Verification';
import ChangePassword from './pages/ChangePassword';
import Vendor from './pages/Vendor';


function App() {
  
  return (
    <Router>
      <Switch>
        <Route  path="/"  component={ Login } exact/>
        <Route  path="/track"  component={ Tracking } exact/>
        <Route  path="/triplog"  component={ TripLog } exact/>
        <Route  path="/busstaff"  component={ BusStaff } exact/>
        <Route path="/adminStaff" component={ AdminStaff } exact/>
        <Route path="/studentList" component={ StudentList } exact/>
        <Route path="/masterStudentList" component={ MasterStudentList } exact/>
        <Route path="/studentHistory" component={ StudentHistory } exact/>
        <Route path="/viewId" component={  ViewId } exact/>
        <Route path="/masterBusList" component={  MastersBuses } exact/>
        <Route path="/classList" component={   MasterClasses } exact/>
        <Route path="/routeList" component={  MastersRouteList } exact/>
        <Route path="/notification" component={  MasterNotification } exact/>
        <Route path="/roles" component={  MasterRoles } exact/>
        <Route path="/login" component={ Login } exact/>
        <Route path="/reset_paswd" component={ ResetPassword } exact/>
        <Route path="/verification" component={ Verification } exact/>
        <Route path="/changePaswrd" component={ ChangePassword } exact/>
         <Route path ='/vendor' component={ Vendor } exact/>
      </Switch>
    </Router>

   
  );
}

export default App;
