import React,{useEffect,useState,useMemo} from 'react'
import { useHistory } from 'react-router-dom'
import DashboardLayout from '../components/DashboardLayout/DashboardLayout'
import Classes from '../components/RecentActivity/RecentActivity.module.css'
import { GoogleMap,useLoadScript,MarkerF } from '@react-google-maps/api';
import { IoIosArrowDown } from 'react-icons/io'
import BusDetail from '../components/LiveTrack/BusDetail'
import moment from "moment";
// import profileStudent from "../assets/images/profile.png";
import { BsCircleFill } from "react-icons/bs";
import CountCard from '../components/CountCard/CountCard'
import DetailModal from '../components/DetailModal/DetailModal'
// import profile from '../assets/images/profile.png'
import {FaCircle} from 'react-icons/fa'
import { SlLocationPin } from 'react-icons/sl'
import Bus from '../assets/images/bus.png'
import { Select } from 'antd';
import RecentActivity from '../components/RecentActivity/RecentActivity'
import * as urls from "../Urls";
import axios from 'axios'
import StartFirebase from '../components/firebaseConfig';
import {ref as sRef,get,set,child,onValue} from 'firebase/database'
import 'firebase/database';



function Map(props) {
return (
    <>
    <GoogleMap zoom={11} center={props.position} mapContainerClassName="map-container">
      
            {props.markerLists.map((item,index)=>{

                return(
                  item.position.lat!==0? <MarkerF
                    key={index}
                    position={item.position}
                    label={{text:item.name,className:'mapLabel'}}
                    onClick={()=>props.viewDetailHandler(item,'map')}
                    >
                  </MarkerF>:null
                 
                )
          })}
          {props.busPostion.lat!==0? <MarkerF
              position={props.busPostion}
                icon={{
                  url: (require('../assets/images/busmark.png')),
                fillColor: '#EB00FF',
                scale: 7,
              }}
              // onClick={()=>viewDetailHandler(item,'map')}
            >
            </MarkerF>:null}
         
      </GoogleMap>
    </>
  );

}



const Tracking = () =>{
    const[db,setDb] = useState(StartFirebase())
    const[tripIdList,setTripIdList] = useState([])
    const[tripOptions,setTripOptions] = useState([{value:'',label:''}])
    const[tripSelectId,setTripSelectId] = useState('')
    // const[tripDetSelect,setTripDetSelect] = useState([])
    const[value,setValue] = useState('')
    const [startLoc,setStartLoc] = useState('')
    const [studentOnBoard,setStudentOnDeBoard] = useState('')
    const [studentDeboard,setStudentDeboard] = useState('')
    const[routeName,setRouteName] = useState('')
    const[driverName,setDriverName] = useState('')
    const[studentLists,setStudentLists] = useState([])
    const[showDet,setShowDet] = useState(false)
    const[selectStudentDet,setSelectStudentDet] = useState({name:'',onBTime:'',onDTime:'',onBL:'',onDL:'',img:''})
    const [markerList,setMarkerList] = useState([{id:'',name:'',img:'',
                                                  onboarded_locationtext:'',
                                                  onboarded_time:'',
                                                  deboarded_locationtext:'',deboarded_time:'',
                                                  position:{lat:0,lng:0}}])
    const[busNo,setBusNo] = useState('')
    const[busName,setBusName] = useState('')
    const[busStaffId,setBusStaffId] = useState('')
    const[location,setLocation] = useState({lat:0,lng:0,time:0})

    const history = useHistory()

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: 'AIzaSyDMhFSZ3VEkYDLrsgyxWoanDij64NvhZYE',
    });
    // const center = useMemo(() => ({lat:25.281640,lng:51.524300}), []);
    // const nextCenter = useMemo(()=>({lat:24.140329,lng:120.662910}),[])
   
   
     useEffect(()=>{
        axios.get(urls.tripList, { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
             console.log('triplist',response.data.results)
              setBusStaffId(response.data.results[0].bus_staff.id)
              const locationRef = sRef(db,'bus_staff/'+response.data.results[0].bus_staff.id);
              onValue(locationRef, (snapshot) => {
              
                if( (typeof(snapshot.val().lat === Number)) && (typeof(snapshot.val().lng === Number))){
                  setLocation(snapshot.val())
                  console.log('fbval',snapshot.val());
                }
                else{
                  setLocation({lat:25.281640,lng:51.524300})
                }
                
              });
                const newList = []
                for(let i=0;i<response.data.results.length;i++){
                    newList.push({ value: response.data.results[i].id,label: 'Trip:#'+''+ response.data.results[i].id,})
                }
                setTripOptions(newList)
                localStorage.setItem('val',response.data.results[0].id)
                setValue(response.data.results[0].id)
                setTripIdList(response.data.results)
                setTripSelectId(response.data.results[0].id)
                axios.get(urls.tripListDet+response.data.results[0].id, { headers: { Authorization: "Bearer " + token } })
                .then((response1) => {
                    console.log('tripDet',response1.data);
                    // setTripDetSelect(response1.data.results)
                    setStartLoc(response1.data.results[0].start_locationtext)
                    setStudentOnDeBoard(response1.data.results[0].onboarded_count)
                    setStudentDeboard(response1.data.results[0].deboarded_count)
                    setRouteName(response1.data.results[0].route_id.name)
                    setDriverName(response1.data.results[0].bus_staff.name)
                    setBusName(response1.data.results[0].vehicle_id.register_no)
                    setBusNo(response1.data.results[0].vehicle_id.bus_no)
                 })
                .catch((error) => {
                  console.log(error);
                });
                axios.get(urls.tripsBystudent+response.data.results[0].id +'/students', { headers: { Authorization: "Bearer " + token } })
                .then((response2) => {
                  console.log('trips in a student',response2);
                 
                  if(response2.data.results.length !==0){
                     let studentArray = []
                    for(let i=0;i<response2.data.results.length;i++){
                      if(((response2.data.results[i].student_id.latitude !==null)&&(response2.data.results[i].student_id.latitude !=="")) && 
                              ((response2.data.results[i].student_id.longitude !==null)&&(response2.data.results[i].student_id.longitude!==""))){
                                  studentArray.push({id:response2.data.results[i].student_id.id,
                                  name:response2.data.results[i].student_id.name,
                                  img:response2.data.results[i].student_id.image,
                                  onboarded_locationtext:response2.data.results[i].onboarded_locationtext,
                                  onboarded_time:response2.data.results[i].onboarded_time,
                                  deboarded_locationtext:response2.data.results[i].deboarded_locationtext,
                                  deboarded_time:response2.data.results[i].deboarded_time,
                                  position:{lat:JSON.parse(response2.data.results[i].student_id.latitude),
                                  lng:JSON.parse(response2.data.results[i].student_id.longitude)}
                                
                              })
                            }
                            }
                           
                            setMarkerList(studentArray)
                            setStudentLists(response2.data.results)
                    }
                    else{
                      setStudentLists(response2.data.results)
                      setMarkerList([{id:'',name:'',img:'',
                      onboarded_locationtext:'',
                      onboarded_time:'',
                      deboarded_locationtext:'',deboarded_time:'',
                      position:{lat:0,lng:0}}])

                    }
                  
                 })
                .catch((error) => {
                  console.log(error);
                });
        })
        .catch((error) => {
          console.log(error);
        });
       
    },[])
    const token = localStorage.getItem("busAdmin");
      const onChange = (value) => {
              setMarkerList([{id:'',name:'',img:'',
              onboarded_locationtext:'',
              onboarded_time:'',
              deboarded_locationtext:'',deboarded_time:'',
              position:{lat:0,lng:0}}])
       setValue(value)
       localStorage.setItem('val',value)
       console.log(value);
        axios.get(urls.tripListDet+value, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
            console.log('tripDet change',response1.data);
            const locationRef = sRef(db,'bus_staff/'+response1.data.results[0].bus_staff.id);
            onValue(locationRef, (snapshot) => {
             
              if( (typeof(snapshot.val().lat === Number)) && (typeof(snapshot.val().lng === Number))){
                setLocation(snapshot.val())
                console.log('fbval update',snapshot.val());
              }
              else{
                setLocation({lat:25.281640,lng:51.524300})
              }
            });
            setBusStaffId(response1.data.results[0].bus_staff.id)
            // setTripDetSelect(response1.data.results)
            setStartLoc(response1.data.results[0].start_locationtext)
            setStudentOnDeBoard(response1.data.results[0].onboarded_count)
            setStudentDeboard(response1.data.results[0].deboarded_count)
            setRouteName(response1.data.results[0].route_id.name)
            setDriverName(response1.data.results[0].bus_staff.name)
            setBusName(response1.data.results[0].vehicle_id.register_no)
            setBusNo(response1.data.results[0].vehicle_id.bus_no)
         })
        .catch((error) => {
          console.log(error);
        });
        axios.get(urls.tripsBystudent+value+'/students', { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
          console.log('trips in a student change',response2.data);
         if(response2.data.results.length !==0 ){
            let studentArray = []
                for(let i=0;i<response2.data.results.length;i++){
                    if(((response2.data.results[i].student_id.latitude !==null)&&(response2.data.results[i].student_id.latitude !=="")) &&
                        ((response2.data.results[i].student_id.longitude !==null)&&(response2.data.results[i].student_id.longitude!==""))){
                            studentArray.push({id:response2.data.results[i].student_id.id,
                              name:response2.data.results[i].student_id.name,
                              img:response2.data.results[i].student_id.image,
                              onboarded_locationtext:response2.data.results[i].onboarded_locationtext,
                              onboarded_time:response2.data.results[i].onboarded_time,
                              deboarded_locationtext:response2.data.results[i].deboarded_locationtext,
                              deboarded_time:response2.data.results[i].deboarded_time,
                              position:{lat:JSON.parse(response2.data.results[i].student_id.latitude),
                              lng:JSON.parse(response2.data.results[i].student_id.longitude)}
                      })
                    }
                }
                setMarkerList(studentArray)
                setStudentLists(response2.data.results)
                console.log('student array',studentArray);
          }
          else{
                setStudentLists(response2.data.results)
                setMarkerList([{id:'',name:'',img:'',
                  onboarded_locationtext:'',
                  onboarded_time:'',
                  deboarded_locationtext:'',deboarded_time:'',
                  position:{lat:0,lng:0}}])
                }
          })
        .catch((error) => {
          console.log(error);
        });
      };
      
      useEffect(() => {
        const interval =  setInterval(() => {
          console.log('This will run every one second!');
          console.log('change val',localStorage.getItem('val'));
          const valChng = localStorage.getItem('val')
         axios.get(urls.tripListDet+valChng, { headers: { Authorization: "Bearer " + token } })
                  .then((response1) => {
                      console.log('tripDet',response1.data);
                      // setTripDetSelect(response1.data.results)
                      setStartLoc(response1.data.results[0].start_locationtext)
                      setStudentOnDeBoard(response1.data.results[0].onboarded_count)
                      setStudentDeboard(response1.data.results[0].deboarded_count)
                      setRouteName(response1.data.results[0].route_id.name)
                      setDriverName(response1.data.results[0].bus_staff.name)
                      setBusName(response1.data.results[0].vehicle_id.register_no)
                      setBusNo(response1.data.results[0].vehicle_id.bus_no)
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                  axios.get(urls.tripsBystudent+valChng+'/students', { headers: { Authorization: "Bearer " + token } })
                  .then((response2) => {
                    console.log(response2)
                    setStudentLists(response2.data.results)
                     
                  })
                  .catch((error) => {
                    console.log(error);
                  });
          }, 1000);
        return () => clearInterval(interval);
      }, []);
      const studentListHandler = () =>{
        history.push({
          pathname:'/studentList',
          state:{
              tripId:value,
              page:'track'
          }
      })

      }
      const viewDetailHandler = (studentDet,type) =>{
       setShowDet(true)
        let onLoc;
        let dLoc;
        let onT;
        let dT;
        if(studentDet.onboarded_locationtext === null){
            onLoc = 'NA'
        }
        else{
            onLoc = studentDet.onboarded_locationtext
        }
        if(studentDet.deboarded_locationtext === null){
            dLoc = 'NA'
        }
        else{
            dLoc = studentDet.deboarded_locationtext
        }
        if(studentDet.onboarded_time === null){
            onT = 'NA'
        }
        else{
            onT = moment(studentDet.onboarded_time).format("LT")
        }
        if(studentDet.deboarded_time === null){
            {console.log(studentDet);}
            dT = 'NA'
        }
        else{
            dT = moment(studentDet.deboarded_time).format("LT")
        }
        if(type==='view'){
          setSelectStudentDet(    
            {name:studentDet.student_id.name,onBTime:onT,onDTime:dT,onBL: onLoc,onDL:dLoc,img:'https://bus.goldzin.com'+studentDet.student_id.image}
           )
        }
        if(type === 'map'){
          setSelectStudentDet(    
            {name:studentDet.name,onBTime:onT,onDTime:dT,onBL: onLoc,onDL:dLoc,img:'https://bus.goldzin.com'+studentDet.img}
           )
         }
    }
    const detCloseHandler = () =>{
        setShowDet(false)
    }
    return(
        <DashboardLayout selectKey={['2']}>
            {/* view detail popup  */}
                 < DetailModal showDet={showDet} detHandleClose={detCloseHandler}>
                    <div className='d-flex'>
                        <img src={selectStudentDet.img} className='img-fluid studnt' alt='prof' width='40px' height='40px' />
                        <div className='profile'>
                            <h5>{selectStudentDet.name}</h5>
                            {/* <h6>#ZHD10025</h6> */}
                        </div>
                        </div>
                        <div className='modalCont'>
                            {/* <div  style={{paddingBottom:'15px'}}>
                            <h5>Bus number</h5>
                            <span>5</span>

                            </div> */}
                            {/* <div  style={{paddingBottom:'15px'}}>
                            <h5>Trip number</h5>
                            <span>BH10243</span>

                            </div> */}
                            <div  style={{paddingBottom:'15px'}}>
                            <h5>Onboarded time</h5>
                            <span>{selectStudentDet.onBTime}</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                            <h5>Deboarded time</h5>
                            <span>{selectStudentDet.onDTime}</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                            <h5>Onboarded location</h5>
                            <span><SlLocationPin color='#0D77BD' size={20}/> {selectStudentDet.onBL}</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                                <h5>Deboarded location</h5>
                                <span><SlLocationPin color='#0D77BD' size={20}/>{selectStudentDet.onDL}</span>
                            </div>
                        </div>
                </DetailModal>
                {/* triplog popup  */}
                 {/* < DetailModal>
                    <div className='tripView'>
                        <div className='d-flex'>
                        <img src={Bus} className='img-fluid' alt='prof'/>
                        <div className='profile'>
                            <h5>Trip code</h5>
                            <h6>#ZHD10025</h6>
                        </div>
                        </div>
                        
                        <div className='date'>
                            <h5>Date</h5>
                            <h6>18th Nov 2022</h6>

                        </div>
                        </div>
                        <div className='modalCont'>
                            <div  style={{paddingBottom:'15px'}}>
                            <h5>Route</h5>
                            <span>#155</span>

                            </div>
                            <div  style={{paddingBottom:'15px'}}>
                            <h5>Bus </h5>
                            <span>5</span>

                            </div>
                            <div  style={{paddingBottom:'15px'}}>
                            <h5>Start Time</h5>
                            <span>07:30 AM</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                            <h5>End time</h5>
                            <span>07:30 AM</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                            <h5>Students Onboarded</h5>
                            <span>40</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                                <h5>Students Deboarded</h5>
                                <span>40</span>
                            </div>
                            <div style={{paddingBottom:'15px'}}>
                            <h5>Start point</h5>
                            <span><SlLocationPin color='#0D77BD' size={20}/> Al Khor Area Abdulla Bin Nasser Elmesnad Street</span>

                            </div>
                            <div style={{paddingBottom:'15px'}}>
                                <h5>End point</h5>
                                <span><SlLocationPin color='#0D77BD' size={20}/> Al Khor Area Abdulla Bin Nasser Elmesnad Street</span>
                            </div>
                        </div>
                    </DetailModal> */}
                    {/* student list view detail popup */}
                    {/* < DetailModal>
                                <div className='d-flex'>
                                    <img src={profile} className='img-fluid' alt='prof'/>
                                    <div className='profile'>
                                        <h5>Shadiya Shameer</h5>
                                        <h6>#ZHD10025</h6>
                                    </div>
                                    </div>
                                    <div className='modalCont'>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Age</h5>
                                        <span>12</span>

                                        </div>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Date of Birth</h5>
                                        <span>12/12/2002</span>

                                        </div>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Class</h5>
                                        <span>7</span>

                                        </div>
                                        <div style={{paddingBottom:'15px'}}>
                                        <h5>Parent details</h5>
                                        <span>Alfiya Althousi Shakeer Saheb</span>

                                        </div>
                                        <div style={{paddingBottom:'15px'}}>
                                        <h5>Emergency Contact </h5>
                                        <span>+974 558556 25252 </span>

                                        </div>
                                        
                                    </div>
                    </DetailModal> */}
                    
            <div className='mainPad'>
                <div className='mapTrack'>
                    <div className='Track'>
                        <p>Tracking</p>
                                <Select
                                   value={value}
                                     style={{
                                    width: 200,
                                    }}
                                    onChange={onChange}
                                    options={tripOptions}
                            />
                        {/* <div className='trackBtn'>Route : #21<IoIosArrowDown  color='#EBEBEB' size={20}/></div> */}
                    </div>
                        <div onClick={studentListHandler}><span className='viewStudent'>View Student List</span></div>
                </div>
                <div className='d-flex'>
                    <p className='liveTrack'>NOW ON THE WAY</p><div className='line'></div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        { !isLoaded? <div>Loading...</div>: <Map position={markerList[0].position.lat===''?{lat:25.281640,lng:51.524300}:markerList[0].position}
                         markerLists={markerList}
                         busPostion={{lat:location.lat,lng:location.lng}}
                         viewDetailHandler={viewDetailHandler}/>}
                    </div>
                    <div className='col-md-4'>
                        <BusDetail curntLoc={startLoc} route={routeName} driver={driverName} busNo={busNo} busName={busName}/>
                        <CountCard head='STUDENTS ONBOARDED' count={studentOnBoard}/>
                        <CountCard head='STUDENTS DEBOARDED' count={studentDeboard}/>
                    </div>
                </div>
            </div>
                                    
            <div className={Classes.RecentActivity}>
      <h3>Recent Activity</h3>
      <div className={Classes.Track}>
        {studentLists.map((item,index)=>{
          
            let locationStatus;
            let status;
            let color;
            let time;
            let show;

            if(item.attendance_status === 'OnBoarded'){
                locationStatus = item.onboarded_locationtext;
                status = 'OnBoarded';
                color ='#37BE5C'
                time = item.onboarded_time
                show = true
            }
            else if(item.attendance_status === 'DeBoarded'){
                locationStatus = item.deboarded_locationtext
                status = 'DeBoarded'
                color ='#8F8F8F'
                time = item.deboarded_time
                show = true

            }
            else if(item.attendance_status === 'Absent'){
                locationStatus = ''
                status = 'Absent'
                color ='F88379'
                time = ''
                show = false

            }
            return(
                <div className={Classes.Track} key={index}>
                <div className="d-flex">
                  <div className={Classes.Circle}></div>
                  <span className={Classes.Time}>{show?moment(time).format("LT"):null}</span>
                  <div className={Classes.StudentBox}>
                    <div className={Classes.Align}>
                      <div className="d-flex">
                        <img src={'https://bus.goldzin.com'+item.student_id.image} alt="profiles" width='40px' height='40px' className='studnt' />
                        <span className={Classes.Name}>{item.student_id.name}</span>
                        <div className={Classes.BorderRight}></div>
                        <div className={Classes.location}>
                        {show? <SlLocationPin color="#0D77BD" size={18}/>:null}
                          {
                         locationStatus
                          }
                        </div>
                      </div>
                      <div className={Classes.Status}>
                        <span className={Classes.OnBoard}><FaCircle color={color}/>&nbsp;&nbsp;{status}</span>
                        <span className={Classes.StatusDisplay} onClick={()=>viewDetailHandler(item,'view')} style={{cursor:'pointer'}}>view details</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Classes.vl}></div>
              </div>
            )
        })}
        </div>
      </div>
      
        </DashboardLayout>

    )
}
export default Tracking