import React,{useState} from 'react'
import AuthLayout from "../components/AuthLayout/AuthLayout";
import Modal from 'react-bootstrap/Modal';
import succTick from '../assets/images/suctick.gif'
import { AiOutlineEye } from 'react-icons/ai'

export default function ChangePassword() {
    const[show,setShow] = useState(false)
    const HandleClose = () =>{
        setShow(false)
    }
  return (
    <AuthLayout>
        <Modal show={show} onHide={HandleClose} centered>
            <Modal.Header closeButton >
                 <Modal.Title></Modal.Title>
            </Modal.Header>
             <Modal.Body style={{marginLeft:'20px',marginRight:'20px'}}>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={succTick} alt='success' width={150} height={150}/>
                </div>
                <h3 className='pswdUpd'>Password updated</h3>
                <p className='pswdUpdPara'>Your password has been Updated successfully!</p>
                <div className="row">
                <div className="col-md-12">
                    <div className="loginBtn">LOGIN NOW</div>
                </div>
                </div>
             </Modal.Body>
                <Modal.Footer></Modal.Footer>
      </Modal>
    <div className=" justify-content-center align-items-center login">
      <div className="col-md-5">
        <h3 className="welcome">New Credentials</h3>
        <p className="welcomePara">Please enter new credentials</p>
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="label" style={{paddingBottom:'10px'}}>New Password</label>
              <br />
              <div className='d-flex'>
                <input type="password" className="form-control" />
                < AiOutlineEye style={{marginLeft:'-30px',marginTop:'8px'}} size={23}/>

              </div>
             
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label" style={{paddingBottom:'10px'}}>Confirm Password</label>
              <br />
              <input type="password" className="form-control" />
            </div>
          </div>
      
          <div className="row">
            <div className="col-md-12">
              <div className="loginBtn">UPDATE</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </AuthLayout>
  )
}
