import React,{useEffect,useState} from 'react'
import DashboardLayout from '../components/DashboardLayout/DashboardLayout'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { GoPrimitiveDot } from 'react-icons/go'
import axios from 'axios'
import MoonLoader from "react-spinners/MoonLoader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import * as urls from "../Urls";
import DetailModal from "../components/DetailModal/DetailModal";
const StudentList = (props) =>{
    const [tripId,setTripId] = useState('')
    const [studentList,setStudentList] = useState([])
    const[pageCount,setPageCount] = useState('')
    const[loading,setLoading] = useState(false)
    const[show,setShow] = useState(false)
    const [studentDet, setStudentDet] = useState({
        name: "",
        admnNo: "",
        img: "",
        clas: "",
        parName: "",
        contct: "",
        bldGrp:'',
      
      });
    const token = localStorage.getItem("busAdmin");
    const fetchStudent = (currentPage) =>{
        setLoading(true)
        axios.get(urls.tripsBystudent+props.location.state.tripId+'/students'+'?page='+currentPage, { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
                    setLoading(false)
                    setStudentList(response.data.results)
                    setPageCount(response.data.count/10)
                    console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

    }
    useEffect(()=>{
        setLoading(true)
        setTripId(props.location.state.tripId)
        fetchStudent('1')
       
    },[])
    const handlePageClick = (data) =>{
        fetchStudent(data.selected+1)
    }
    const viewHandler = (student) =>{
        console.log(student)
        setShow(true)
        setStudentDet({
            name: student.student_id.name,
            admnNo: student.student_id.admission_number,
            img: student.student_id.image,
            // clas: student.class_id.class + student.class_id.division,
       
            // parName: student.parent_id.name,
             contct: student.parents_phone_code + student.parents_phone_number,
            // bldGrp:student.blood_group,
          
          });

    }
    const studentCloseHanlder = () =>{
        setShow(false)
    }
    let students;
    if(loading){
        students = (
            <div
            className="d-flex justify-content-center"
            style={{
              paddingTop: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
             
            }}
          >
        <MoonLoader color="#0D2641"/>
        </div>
        )
    }
    else{
        students =   <table className='table'>
        <tr className='firstRow'>
            <th>Sl no.</th>
            <th>Student Name</th>
            <th>Parent details</th>
            <th>Pickup time</th>
            <th>Drop location</th>
            <th>Drop time</th>
            <th>Action</th>
          
            
        </tr>
        {studentList.map((item,index)=>{
            return(
                <tr className='rowItems' key={index}>
                    <td>{index+1}</td>
                    <td>{item.student_id.name}</td>
                    <td>{item.onboarded_locationtext}</td>
                    <td>{moment(item.onboarded_time).format('MM/DD/YYYY  h:mm a')}</td>
                    <td>{item.deboarded_locationtext}</td>
                    <td>{moment(item.deboarded_time).format('MM/DD/YYYY  h:mm a')}</td>
                    <td><AiOutlineExclamationCircle color='#33BBE0' size={35} onClick={()=>viewHandler(item)} style={{cursor:'pointer'}}/></td>
                    </tr>

            )
        })}
    </table>
    }
    return(
        <DashboardLayout selectKey={props.location.state.page==='track'?['2']:['3']}>
             <DetailModal showDet={show} detHandleClose={studentCloseHanlder}>
        <div className="d-flex">
          <img
            src={"https://bus.goldzin.com/" + studentDet.img}
            width="50px"
            height="50px"
            className="studnt"
            alt="prof"
          />
          <div className="profile">
            <h5>{studentDet.name}</h5>
            <h6>{studentDet.admnNo}</h6>
          </div>
        </div>
        <div className="modalCont">
         
          <div style={{ paddingBottom: "15px" }}>
            <h5>Class</h5>
            <span>{studentDet.clas}</span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <h5>Blood Group</h5>
            <span>{studentDet.loc}</span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <h5>Parent details</h5>
            <span>{studentDet.parName}</span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <h5>Emergency Contact </h5>
            <span>{studentDet.contct} </span>
          </div>
        
        </div>
      </DetailModal>
             <div className='Dashboard'>
                <div className='selectBox'>
                    <h3 className='Head'>Students List</h3>
                    {/* <div className='addStaff'>EXPORT</div> */}
                 </div>
              
               {students}
            </div>
            <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />
        </DashboardLayout>

    )
}
export default StudentList