import React from 'react'
import AuthLayout from "../components/AuthLayout/AuthLayout";

export default function ResetPassword() {
  return (
    <AuthLayout>
    <div className=" justify-content-center align-items-center login">
      <div className="col-md-5">
        <h3 className="welcome">Reset Passsword</h3>
        <p className="welcomePara">Please enter your Mobile number</p>
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="label" style={{paddingBottom:'10px'}}>Mobile Number</label>
              <br />
              <input type="text" className="form-control" />
            </div>
          </div>
      
          <div className="row">
            <div className="col-md-12">
              <div className="loginBtn">NEXT</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </AuthLayout>
  )
}
