import React,{useState,useEffect} from 'react'
import DashboardLayout from '../components/DashboardLayout/DashboardLayout'
import {BiEdit} from 'react-icons/bi'
import ChangeRole from '../components/DetailModal/ChangeRole'
import CreateStaff from '../components/DetailModal/CreateStaff'
import axios from 'axios'
import * as urls from "../Urls";
import * as country from "../countryList";
import Switch from "react-switch";
import ReactPaginate from "react-paginate";
import MoonLoader from "react-spinners/MoonLoader";
import ReactFlagsSelect from "react-flags-select";

const BusStaff = () =>{
    const [show, setShow] = useState(false);
    const [pageCount,setPageCount] = useState('')
    const [loading, setLoading] = useState(false);
    const [busStafList,setBusStafList] = useState([])
    const [name,setName] = useState('')
    const [selected, setSelected] = useState("QA");
    const [contact,setContact] = useState('')
    const [routeList,setRouteList] = useState([])
    const [vehicleList,setVehicleList] = useState([])
    const [route,setRoute] = useState('')
    const [vehicle,setVehicle] = useState('')
    const [nameError,setNameError] = useState('')
    const [contactError,setContactError] = useState('')
    const [routeNameError,setRouteNameError] = useState('')
    const [vehicleError,setVehicleErrir] = useState('')
    const [editShow,setEditShow] = useState(false)
    const [changeShow,setChangeShow] = useState(false)
    const [status,setStatus] = useState(true)
    const[staffId,setStaffId] = useState('')
    const token = localStorage.getItem("busAdmin");
    const fetchBusStaf = (currentPage) =>{
        setLoading(true)
      axios
      .get(urls.busStaf+'?page=' + currentPage, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response2) => {
        setBusStafList(response2.data.results);
        setLoading(false)
        setPageCount(response2.data.count/10)
      })
      .catch((error) => {
        console.log(error);
      });

    }
    const fetchRoute = () =>{
        axios
        .get(urls.listRoutes, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response2) => {
            setRouteList(response2.data.results)
         
        })
        .catch((error) => {
          console.log(error);
        });
    }
    const fetchVendor = () =>{
        axios
        .get(urls.listVehicles, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response2) => {
            setVehicleList(response2.data.results)
         
        })
        .catch((error) => {
          console.log(error);
        });

    }
    useEffect(()=>{
        fetchBusStaf('1')
        fetchRoute()
        fetchVendor()

    },[])
    const handleClose = () =>{ 
        setShow(false)   
        setNameError('')
        setRouteNameError('')
        setVehicleErrir('')
        setContactError('') 
        setName('')
        setSelected('QA')
        setContact('')
        setRoute('')
        setVehicle('')
    }
    const addStafHandler = () =>{   setShow(true) }
   
    const  handleChange = (busId,status) =>{
        setChangeShow(true)
        setStaffId(busId)
        setStatus(status)
     }
    const editShowHandler = (selItem) =>{
        setEditShow(true)
        setName(selItem.name)
        setContact(selItem.phone_number)
        setStaffId(selItem.id)
        setRoute(selItem.route_id.id)
        setVehicle(selItem.vehicle_id.id)
        let selObj = country.arrayCountryList.find(
            (item) => item.code === selItem.phone_code
          );
          setSelected(selObj.name)

    }
    const handlePageClick = (data) =>{ fetchBusStaf(data.selected+1) }
    const nameChangeHandler = (e) =>{   setName(e.target.value) }
    const contactChangeHandler = (e) =>{ setContact(e.target.value)  }
    const routeChangeHandler = (e) =>{ setRoute(e.target.value) }
    const vehicleChangeHandler = (e) =>{ setVehicle(e.target.value) }
    const editStatusHandler = () =>{
        const body = {
            is_active:!status
        }
        axios.patch(urls.busStaf+'/'+staffId,body, { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
            setChangeShow(false)
            setStaffId('')
            fetchBusStaf('1')
        })
        .catch((error) => {
            console.log(error);
        });
    
      }
    const validate = () => {
        let nameError;
        let contactError;
        let routeNameError;
        let vehicleError;
        if (!name) {
          nameError = "This field is required";
          setNameError(nameError);
        }
        if (!contact) {
          contactError = "This field is required";
          setContactError(contactError);
        }
        if (!route) {
          routeNameError = "This field is required";
          setRouteNameError(routeNameError);
        }
        if(!vehicle){
            vehicleError = "This field is required";
            setVehicleErrir(vehicleError)
        }
        if (nameError || contactError || routeNameError || vehicleError) {
          setNameError(nameError);
          setContactError(contactError);
          setRouteNameError(routeNameError);
          setVehicleErrir(vehicleError)
    
          return false;
        }
        return true;
      };
      const handleEditClose =()=>{
        setEditShow(false)
        setName('')
        setContact('')
        setSelected('QA')
        setRoute('')
        setVehicle('')
      }
      const changeCloseHanlder = () =>{ setChangeShow(false) }
      const editBusStafHandler = (e) =>{
        e.preventDefault();
        const isValidate = validate();
        if (isValidate) {
            setNameError('')
            setRouteNameError('')
            setVehicleErrir('')
            setContactError('')
        let selObj = country.arrayCountryList.find(
            (item) => item.name === selected
          );
        const body = {
            name:name,
            phone_number:contact,
            phone_code:selObj.code,
            route_id:route,
            vehicle_id:vehicle
        }
        axios
        .patch(urls.busStaf+'/'+staffId, body, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response2) => {
            fetchBusStaf('1')
            setEditShow(false)
            setName('')
            setRoute('')
            setContact('')
            setVehicle('')
        })
        .catch((error) => {
          console.log(error);
        });
    }

      }
    const createBusStafHandler = (e) =>{
        e.preventDefault();
        const isValidate = validate();
        if (isValidate) {
            setNameError('')
            setRouteNameError('')
            setVehicleErrir('')
            setContactError('')
        let selObj = country.arrayCountryList.find(
            (item) => item.name === selected
          );
        const body = {
            name:name,
            phone_number:contact,
            phone_code:selObj.code,
            route_id:route,
            vehicle_id:vehicle
        }
        axios
        .post(urls.busStaf, body, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response2) => {
            fetchBusStaf('1')
            setShow(false)
            setName('')
            setRoute('')
            setContact('')
            setVehicle('')
        })
        .catch((error) => {
          console.log(error);
        });
    }
}
    let busStafLists;
    if (loading) {
        busStafLists = (
        <div
            className="d-flex justify-content-center"
            style={{
            paddingTop: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <MoonLoader color="#0D2641" />
        </div>
    );
  } else {
    busStafLists = ( <table className="table" style={{ width: "100%", marginLeft: "0px" }}>
        <thead></thead>
        <tbody>
            <tr className='firstRow'>
                <th>Sl no.</th>
                <th>Name</th>
                <th>Vehicle</th>
                <th>Route</th>
                <th>Contact</th>
                <th >status</th>
                <th className="action">Action</th>
            </tr>
            {busStafList.map((item,index)=>{
                return(
                    <tr className="rowItems" key={index}>
                        <td>{index+1}</td>
                        <td>{item.name}</td>
                        <td>{item.vehicle_id.register_no}</td>
                        <td>{item.route_id.route_name}</td>
                        <td>{item.phone_code+item.phone_number}</td>
                        <td> <Switch
                            onChange={() => handleChange(item.id, item.is_active)}
                            checked={item.is_active}
                            checkedIcon={false}
                            uncheckedIcon={true}
                            height={20}
                            width={40}
                  /></td>
                        <td className="action">
                            <BiEdit
                                color="#0D77BD"
                                size={25}
                                style={{ cursor: "pointer" }}
                                onClick={()=>editShowHandler(item)}
                            />
                        </td>

                    </tr>

                )
            })}
        </tbody>
        <tfoot></tfoot>
    </table>
    )
  }
    return(
        <DashboardLayout selectKey={['6']}>
           <ChangeRole
            title=""
            body={"Are you sure you want to "+`${!status?"enable":"disable"}`+ " this Staff?"}
            shows={changeShow}
            handleClose={changeCloseHanlder}
            okHandler={editStatusHandler}
        />
            <CreateStaff title='Create Bus Staff' onHide={handleClose} show={show}>
                <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Name</label><br/>
                        <input type="text" className='inputText' placeholder='Enter your name' onChange={nameChangeHandler} value={name}/>
                        <div className="ErrorMsg">{nameError}</div>
                    </div>
                </div>
              
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Route</label><br/>
                            <select className='selectText' onChange={routeChangeHandler}>
                                <option value="0">Choose Route</option>
                                {routeList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>
                                    )
                                })}
                              
                               
                            </select>
                            <div className="ErrorMsg">{routeNameError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Vehicle</label><br/>
                            <select className='selectText' onChange={vehicleChangeHandler}>
                                <option value="0">Choose Vehicle</option>
                                {vehicleList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.bus_no}</option>

                                    )
                                })}
                              
                            </select>
                            <div className="ErrorMsg">{vehicleError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Contact number</label><br/>
                        <div className="d-flex">
                                <ReactFlagsSelect
                                selected={selected}
                                countries={country.countryList}
                                customLabels={country.countryCode}
                                className="menu-flags"
                                selectedSize={14}
                                fullWidth={false}
                                onSelect={(code) => setSelected(code)}
                                />
                            <input type="text" className='inputText' value={contact} onChange={contactChangeHandler}/>
                          
                        </div>
                        <div className="ErrorMsg">{contactError}</div>
                    </div>
                </div>
                <div className='submit' onClick={createBusStafHandler}>SUBMIT</div>

                </form>
              

            </CreateStaff>
            <CreateStaff title='Edit Bus Staff' onHide={handleEditClose} show={editShow}>
                <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Name</label><br/>
                        <input type="text" className='inputText' placeholder='Enter your name' onChange={nameChangeHandler} value={name}/>
                        <div className="ErrorMsg">{nameError}</div>
                    </div>
                </div>
              
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Route</label><br/>
                            <select className='selectText' onChange={routeChangeHandler}>
                                <option value="0">Choose Route</option>
                                {routeList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>
                                    )
                                })}
                              
                               
                            </select>
                            <div className="ErrorMsg">{routeNameError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Vehicle</label><br/>
                            <select className='selectText' onChange={vehicleChangeHandler}>
                                <option value="0">Choose Vehicle</option>
                                {vehicleList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.bus_no}</option>

                                    )
                                })}
                              
                            </select>
                            <div className="ErrorMsg">{vehicleError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Contact number</label><br/>
                        <div className="d-flex">
                                <ReactFlagsSelect
                                selected={selected}
                                countries={country.countryList}
                                customLabels={country.countryCode}
                                className="menu-flags"
                                selectedSize={14}
                                fullWidth={false}
                                onSelect={(code) => setSelected(code)}
                                />
                            <input type="text" className='inputText' value={contact} onChange={contactChangeHandler}/>
                          
                        </div>
                        <div className="ErrorMsg">{contactError}</div>
                    </div>
                </div>
                <div className='submit' onClick={editBusStafHandler}>SUBMIT</div>

                </form>
              

            </CreateStaff>
            <div className='Dashboard'>
                <div className='selectBox'>
                    <h3 className='Head'>Bus Staff</h3>
                    <div className='addStaff' onClick={addStafHandler}>ADD</div>
                 </div>
                        { busStafLists}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />
                    </div>

        </DashboardLayout>

    )
}
export default BusStaff