import React, { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import CreateStaff from "../components/DetailModal/CreateStaff";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { TbMinusVertical } from "react-icons/tb";
import ChangeRole from "../components/DetailModal/ChangeRole";
import axios from "axios";
import * as urls from "../Urls";
import ReactPaginate from "react-paginate";
import MoonLoader from "react-spinners/MoonLoader"
import Switch from "react-switch";

export default function MasterRoles() {
  const [checked, seChecked] = useState(false);
  const [editRoleShow, setEditRoleShow] = useState(false);
  const [addRoleShow, setAddRoleShow] = useState(false);
  const [id, setId] = useState('');
  const [changeShow, setChangeShow] = useState(false);
  const [rolesList,setRolesList] = useState([]);
  const [nameError, setNameError] = useState('');
  const [selectRoleId, setSelectRoleId] = useState("");
  const [name,setName] = useState('');
  const token = localStorage.getItem("busAdmin");
  const[pageCount,setPageCount] = useState('');
  const [loading, setLoading] = useState(false)
  const[status,setStatus] = useState(true)
  
  const  fetchRoles = (currentPage)=>{
    setLoading(true)
    axios
    .get(urls.roles + currentPage, { headers: { Authorization: "Bearer " + token } })
    .then((response1) => {
      console.log(response1);
      setLoading(false)
      setRolesList(response1.data.results)
      setPageCount(response1.data.count/10)
    })
    .catch((error) => {
      console.log(error);
    });
  }
  useEffect(() => {
      fetchRoles('1')
  }, [addRoleShow,editRoleShow]);
  const closeEditRoleHandler = () => {
    setEditRoleShow(false);
    setName("");
  }
  
  const ediRoletHandler = (roleDets) => {
    console.log(roleDets);
    setEditRoleShow(true);
    setName(roleDets.role_name);
    setSelectRoleId(roleDets.id);
    console.log(roleDets);
  };
  const addRoleHandler = () => {
    setAddRoleShow(true);
  };
  const nameChangeHandler = (e) =>{
    setName(e.target.value)

  }
  const handleChange = (classIdSel,status) => {
    setSelectRoleId(classIdSel)
    setStatus(status)
    console.log(status);
    setChangeShow(true)
   
 };
  const handleCloseRole = () => {
    setAddRoleShow(false);
  };
  const changeCloseHanlder = () => {
    setChangeShow(false);
    setStatus(true)
    setSelectRoleId('')
  };
  const createHandler = () =>{
    const isValidate = validate()
    if(isValidate){
      setNameError('')
    const body = {
                    role_name: name,
                    is_active: true
                }
    axios
    .post(urls.roles,body,{ headers: { Authorization: "Bearer " + token } })
      .then((response) => {
        if(response.data.success=== true){
          setAddRoleShow(false)

        }
        
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
  const submitEditRoleHandler = (e) => {
    e.preventDefault();
    const body = {
      role_name: name
    };
    console.log(body);
    axios.patch(urls.editRoles + selectRoleId, body, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response2) => {
        closeEditRoleHandler()

        console.log(response2);
      })
      .catch((error) => {
        console.log(error);
      });
   
  };
  const editStatusHandler = () =>{
    const body = {
      is_active:!status
   }
  axios.patch(urls.editRoles + '/' + selectRoleId, body,{ headers: { Authorization: "Bearer " + token } })
    .then((response3) => {
      setChangeShow(false)
      setSelectRoleId('')
      fetchRoles('1')
      console.log(response3);
      
    
  })
  .catch((error) => {
    console.log(error);
  });

  }
  const validate = () =>{
    let nameError;
    if(!name){
      nameError = 'This field is required'
      setNameError(nameError)
    }
    if(nameError )
    {
      setNameError(nameError)
      return false
    }
    return true

  }
  const handlePageClick = (data) =>{
    fetchRoles(data.selected+1)
  

  }
  let roless;
  if(loading){
    roless = (
      <div
        className="d-flex justify-content-center"
        style={{
          paddingTop: "250px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
         
        }}
      >
        <MoonLoader color="#0D2641" />
      </div>
    );
      }
      else{
        roless = (
          <table className="table" style={{width:'100%',marginLeft:'0px'}}>
          <thead></thead>
            <tr className="firstRow ">
              <th>Name </th>
              <th>Status</th>
              <th className="action">Action</th>
            </tr>
            {rolesList.map((item,index)=>{
              return(
                <tr className="rowItems " key={item.id}>
                <td>{item.role_name}</td>
                <td>
                
                  <Switch
                  className="Toggle"
                    onChange={()=>handleChange(item.id,item.is_active)}
                    checked={item.is_active}
                    checkedIcon={false}
                    uncheckedIcon={true}
                    height={20}
                    width={40}
                  />
                </td>
  
                <td className="action">
                  <BiEdit
                    color="#0D77BD"
                    size={35}
                    style={{ cursor: "pointer" }}
                    onClick={() => ediRoletHandler(item)}
                    
                  />
                 
                  
                </td>
              </tr>

              )
            })}
        </table>
        )
      }


  return (
    <DashboardLayout selectKey={["14"]}>
      <ChangeRole
        title=""
        body="Are you sure you want to disable this role?"
        shows={changeShow}
        handleClose={changeCloseHanlder}
        okHandler={editStatusHandler}
      />
      <CreateStaff title="Create Role" onHide={handleCloseRole} show={addRoleShow}>
        <form>
          <div className="row">
            <div className="row" style={{ paddingRight: "0px" }}>
              <div className="col-md-12">
                <label className="label">Name</label>
                <br />
                <input type="text" className="inputText" onChange={nameChangeHandler} />
                <div className="ErrorMsg">{nameError}</div>
              </div>
            </div>
          </div>
          <div className="submit" onClick={createHandler}>CREATE</div>
        </form>
      </CreateStaff>
      
      <CreateStaff title="Edit Role"  show={editRoleShow} onHide={closeEditRoleHandler}>
        <form>
          <div className="row">
            <div className="row" style={{ paddingRight: "0px" }}>
              <div className="col-md-12">
                <label className="label">Name</label>
                <br />
                <input type="text" className="inputText" value={name} onChange={nameChangeHandler} />
                
              </div>
            </div>
          </div>
          <div className="submit" onClick={submitEditRoleHandler}>SUBMIT</div>
        </form>
      </CreateStaff>

      <div className="Dashboard">
        <div className="selectBox">
          <h3 className="Head">Roles</h3>
          <div className="addStaff" onClick={addRoleHandler}>
            ADD
          </div>
        </div>
       {roless}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        marginPagesDisplayed={3}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        containerClassName={'pagination justify-content-end pageout'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </DashboardLayout>
  );
}
