import React,{useState} from "react";
import AuthLayout from "../components/AuthLayout/AuthLayout";
import * as urls from '../Urls'
import axios from 'axios'
import {AiOutlineEyeInvisible,AiOutlineEye} from 'react-icons/ai'
import { useHistory } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

export default function Login() {
  const[email,setEmail] = useState('')
  const[visible,setVisible] = useState(false)
  const[password,setPassword] = useState('')
  const[loading,setLoading] = useState(false)
  const history = useHistory()
  const emailHandleChange = (e) =>{
    setEmail(e.target.value)
  }
  const passwordHandleChange = (e) =>{
    setPassword(e.target.value)

  }
  const visibilityChange = () =>{
     setVisible(!visible)

  }
  const loginHandler = () =>{
    setLoading(true)
    const body = {
      "username": email,
      "password" : password
    }
    axios.post(urls.login,body)
    .then(response => {
     if(response.status ===200){
        setLoading(false)
        localStorage.setItem('busAdmin',response.data.access)
        history.push('/track')
      }
       console.log(response);
     
    }).catch ( error =>{
      console.log(error)
    })
  }
  return (
    <AuthLayout>
      <div className=" justify-content-center align-items-center login">
        <div className="col-md-5">
          <h3 className="welcome" style={{color:'#2EA611'}}>Welcome back!</h3>
          <p className="welcomePara">Please enter your details</p>
         {loading?<div className='d-flex justify-content-center align-items-center' style={{paddingTop:'150px'}}><MoonLoader color="#0D2641" /></div>:<form>
            <div className="row">
              <div className="col-md-12">
                <label className="label">Email</label>
                <br />
                <input type="text" className="form-control" onChange={emailHandleChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="label">Password</label>
                <br />
                <div className="d-flex">
                  <input type={visible?"text":"password"} className="form-control" onChange={passwordHandleChange}/>
                  {visible?<AiOutlineEye className="pasVisb" size={20} onClick={visibilityChange}/>:<AiOutlineEyeInvisible className="pasVisb" size={20} onClick={visibilityChange}/>}

                </div>
              
              </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="forgot">
                        <div className="d-flex">
                            <input type='checkbox'/>
                            <p className="keepMe">&nbsp;&nbsp;Keep me logged in</p>
                        </div>
                        <p className="forgotLink">Forgot Password</p>

                    </div>
                </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="loginBtn" onClick={loginHandler}>LOGIN</div>
              </div>
            </div>
          </form>} 
          
        </div>
      </div>
    </AuthLayout>
  );
}
