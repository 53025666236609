import React,{useState,useEffect} from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import {BiEdit} from 'react-icons/bi'
import CreateStaff from '../components/DetailModal/CreateStaff'
import MoonLoader from "react-spinners/MoonLoader";
import axios from "axios";
import * as urls from "../Urls";
import ReactPaginate from "react-paginate";
import Switch from "react-switch";
import ChangeRole from "../components/DetailModal/ChangeRole";

const MastersClassList = () => {
    const[classShow,setClassShow] = useState(false)
    const[loading,setLoading] = useState(false)
    const[classList,setClassList] = useState([])
    const[pageCount,setPageCount] = useState('')
    const[className,setClassName] = useState('')
    const[divisionName,setDivisionName] = useState('')
    const[titleName,setTitleName] = useState('')
    const[classId,setClassId] = useState('')
    const[classError,setClasserror] = useState('')
    const[divisionError,setDivisionError] = useState('')
    const[titleError,setTitleError] = useState('')
    const [changeShow, setChangeShow] = useState(false);
    const[editShow,setEditShow] = useState()
    const[status,setStatus] = useState(true)
    const token = localStorage.getItem("busAdmin");
    const addClassHandler = () =>{
        setClassShow(true)
    }
    const handleClose = () =>{
        setClassShow(false)
        setClassName('')
        setDivisionName('')
        setTitleName('')
    }
    const fetchClass = (cuurentPage) =>{
      setLoading(true)
      axios.get(urls.listClas+cuurentPage, { headers: { Authorization: "Bearer " + token } })
      .then((response1) => {
           setLoading(false);
           setClassList(response1.data.results)
           setPageCount(response1.data.count/10)
        })
        .catch((error) => {
          console.log(error);
        });

    }
    useEffect(()=>{
      fetchClass('1')
    },[])
    const handlePageClick = (data) =>{
      fetchClass(data.selected+1)
      console.log(data.selected+1);
    }
    const editHandleClose = () =>{
      setEditShow(false)
      setClassId('')
      setClassName('')
      setDivisionName('')
      setTitleName('')
    }
    const classChangeHandler = (e) =>{
      setClassName(e.target.value)
      setTitleName(e.target.value+divisionName)

    }
    const divisionChangeHandler =(e) =>{
      setDivisionName(e.target.value)
      setTitleName(className+e.target.value)

    }
   
    const handleChange = (classIdSel,status) => {
      setClassId(classIdSel)
      setStatus(status)
      console.log(status);
      setChangeShow(true)
     
   };
   const titleChangeHandler = () =>{

   }
   const editDisplayHanlder = (selectClas) =>{
    setEditShow(true)
    setClassId(selectClas.id)
    setClassName(selectClas.class_name)
    setDivisionName(selectClas.division)
    setTitleName(selectClas.class_division)
    

   }
    const editStatusHandler = () =>{
      const body = {
        is_active:!status
     }
    axios.patch(urls.classUrl+'/'+classId, body,{ headers: { Authorization: "Bearer " + token } })
      .then((response3) => {
        setChangeShow(false)
        setClassId('')
        fetchClass('1')
        console.log(response3);
        
      
    })
    .catch((error) => {
      console.log(error);
    });

    }
    const editHandler = () =>{
      const body ={ 
          "class_name": className,
          "division" : divisionName,
          "class_division":className+divisionName
      }
      axios.patch(urls.classUrl+'/'+classId, body,{ headers: { Authorization: "Bearer " + token } })
      .then((response4) => {
            setClassId('')
            setClassName('')
            setDivisionName('')
            setEditShow(false)
            setTitleName('')
            fetchClass('1')
      })
    .catch((error) => {
      console.log(error);
    });
    
     

    }
    const changeCloseHanlder = () => {
      setChangeShow(false);
      setStatus(true)
      setClassId('')
    };
    const validate = () =>{
      let classError;
      let divisionError;
      let titleError;
     
      if(!className){
        classError = 'This field is required'
        setClasserror(classError)
      }
      if(!divisionName){
        divisionError = 'This field is required'
        setDivisionError(divisionError)
      }
      if(!titleName){
        titleError = 'This field is required'
        setTitleError(titleError)
      }
     
      if(classError || divisionError || titleError ){
        setClasserror(classError)
        setDivisionError(divisionError)
        setTitleError(titleError)
        return false
      }
      return true
  
    }
    const submitHandler = (e) =>{
      e.preventDefault();
      const isValidate = validate()
      if(isValidate){
        setClasserror('')
        setDivisionError('')
        setTitleError('')
          const body = {
            "class_name": className,
            "division": divisionName,
            "class_division": titleName,
            "is_active":"True"
          }
          axios.post(urls.classUrl, body,{ headers: { Authorization: "Bearer " + token } })
          .then((response2) => {
            setClassName('')
            setDivisionName('')
            setTitleName('')
            setClassShow(false)
            fetchClass('1')
          
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
    
    let classLists;
    if(loading){
      classLists = (
        <div
          className="d-flex justify-content-center"
          style={{
            paddingTop: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
           
          }}
        >
          <MoonLoader color="#0D2641" />
        </div>
      );
    }
    else{
      
      classLists =   <table className="table" style={{width:'100%',marginLeft:'0px'}}>
      <tr className="firstRow">
            <th>Title</th>
            <th>Class</th>
            <th>Division</th>
            <th>Status</th>
            <th className="action" >Action</th>
      </tr>
      <thead></thead>
      <tbody>
      {classList.map((item,index)=>{
        return(
          <tr className="rowItems" key={index}>
            <td>{item.class_division}</td>
            <td>{item.class_name}</td>
            <td>{item.division}</td>
            <td> <Switch
                    onChange={()=>handleChange(item.id,item.is_active)}
                    checked={item.is_active}
                    checkedIcon={false}
                    uncheckedIcon={true}
                    height={20}
                    width={40}
                  /></td>
            <td className="action">
              <BiEdit color="#0D77BD" size={25} onClick={()=>editDisplayHanlder(item)} style={{cursor:'pointer'}}/>
              
            </td>
        </tr>
          
        )
      })}
      </tbody>
      <tfoot></tfoot>
      </table>

    }
  return (
    <DashboardLayout selectKey={["11"]}>
        <ChangeRole
        title=""
        body="Are you sure you want to disable this Class?"
        shows={changeShow}
        handleClose={changeCloseHanlder}
        okHandler={editStatusHandler}
      />
         <CreateStaff title='Create Class'  onHide={handleClose} show={classShow}>
      
      <form>
      <div className='row'>
          <div className='col-md-12'>
              <label className='label'>Class</label><br/>
              <input type="text" 
                    className='inputText' 
                    value={className}
                    placeholder='Enter your Class' onChange={classChangeHandler}/>
              <div className="ErrorMsg">{classError}</div>
          </div>
      </div>
      <div className='row'>
          <div className='col-md-12'>
              <label className='label'>Division</label><br/>
              <input type="text" 
                      className='inputText' 
                      value={divisionName}
                      placeholder='Enter your division' 
                      onChange={divisionChangeHandler}/>
              <div className="ErrorMsg">{divisionError}</div>
          </div>
       
      </div>
     <div className='row'>
          <div className='col-md-12'>
              <label className='label'>Title</label><br/>
              <input type="text" 
                     className='inputText' 
                     value={titleName} 
                     onChange={titleChangeHandler}
               />
              <div className="ErrorMsg">{titleError}</div>
          </div>
      </div>
    
      <div className='submit' onClick={submitHandler}>SUBMIT</div>

      </form>
    

  </CreateStaff>
  <CreateStaff title='Edit Class'  onHide={editHandleClose} show={editShow}>
      
      <form>
      <div className='row'>
          <div className='col-md-12'>
              <label className='label'>Class</label><br/>
              <input type="text" 
                    className='inputText' 
                    value={className}
                    placeholder='Enter your Class' onChange={classChangeHandler}/>
            
          </div>
      </div>
      <div className='row'>
          <div className='col-md-12'>
              <label className='label'>Division</label><br/>
              <input type="text" 
                      className='inputText' 
                      value={divisionName}
                      placeholder='Enter your division' 
                      onChange={divisionChangeHandler}/>
              
          </div>
       
      </div>
     <div className='row'>
          <div className='col-md-12'>
              <label className='label'>Title</label><br/>
              <input type="text" 
                     className='inputText' 
                     value={titleName} 
                     onChange={titleChangeHandler}
                     readOnly
               />
             
          </div>
      </div>
    
      <div className='submit' onClick={editHandler}>SUBMIT</div>

      </form>
    

  </CreateStaff>
      <div className="Dashboard">
        <div className="selectBox">
          <h3 className="Head">Class</h3>
          <div className="addStaff" onClick={addClassHandler}>ADD</div>
        </div>
            {classLists}
            <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< prev"
                  renderOnZeroPageCount={null}
                  containerClassName={'pagination justify-content-end pageout'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
        </div>
    </DashboardLayout>
  );
};
export default MastersClassList;
