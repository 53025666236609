import React, { useState } from 'react'
import AuthLayout from "../components/AuthLayout/AuthLayout";
import OtpInput from 'react-otp-input';

export default function Verification() {
    const[otp,setOtp] = useState('')
    const handleChange = (otp) =>{
        setOtp(otp)

    }
  return (
    <AuthLayout>
    <div className=" justify-content-center align-items-center login">
      <div className="col-md-5">
        <h3 className="welcome">Code Verification</h3>
        <p className="welcomePara">Please enter one time password sent on +48 98765434</p>
        <form>
        <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        separator={<span></span>}
                        isInputNum="true"
                        inputStyle='CodeBox'
                        containerStyle='OtpBsox'
                        />

          <div className="row">
              <div className="col-md-12">
                  <div className="forgot">
                      <div className="d-flex">
                         
                          <p className="keepMe">didn’t receive the code?<span className='resend'> &nbsp;&nbsp;RESEND OTP</span></p>
                      </div>
                     

                  </div>
              </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="loginBtn">VERIFY</div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </AuthLayout>
  )
}
