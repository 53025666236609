import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Classes from './DetailModal.module.css'
const ChangeRole = (props) =>{
    
    return(
        <Modal show={props.shows} onHide={props.handleClose} centered>
        <Modal.Header closeButton >
          <Modal.Title className={Classes.title}>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
          CANCEL
          </Button>
          <Button variant="primary" onClick={props.okHandler}>
          SUBMIT
          </Button>
        </Modal.Footer>
      </Modal>

    )
}
export default ChangeRole