import React,{useState,useEffect} from 'react'
import DashboardLayout from '../components/DashboardLayout/DashboardLayout'
import {BiEdit} from 'react-icons/bi'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import {TbMinusVertical} from 'react-icons/tb'
import CreateStaff from '../components/DetailModal/CreateStaff'
import DetailModal from '../components/DetailModal/DetailModal'
import axios from 'axios'
import MoonLoader from "react-spinners/MoonLoader";
import * as urls from '../Urls'
import ReactPaginate from "react-paginate";

export default function MastersBuses() {
    const [showCreate, setShowCreate] = useState(false);
    const [busList,setBusList] = useState([])
    const [showBus,setShowBus] = useState(false)
    const [pageCount,setPageCount] = useState('')
    const [loading,setLoading] = useState(false)
    const [vendorList,setVendorList] = useState([])
    const [routeList,setRouteList] = useState([])
    const [busDetail,setBusDetail] = useState({num:'',regNo:'',vendor:'',route:'',capacity:'',model:'',year:''})
    const [busNo,setBusNo] = useState('')
    const [modNo,setModNo] = useState('')
    const [year,setYear] = useState('')
    const [capacity,setCapacity] = useState('')
    const[regNo,setRegNo] = useState('')
    const[vendor,setVendor] = useState('')
    const[route,setRoute] = useState('')
    const[busNoError,setBusNoError] = useState('')
    const[modNoError,setModNoError] = useState('')
    const[yearError,setYearError] = useState('')
    const[capacityError,setCapacityError] = useState('')
    const[regNumError,setRegNumError] = useState('')
    const[vendorError,setVendorError] = useState('')
    const[routeError,setRouteError] = useState('')
    const[vehicleId, selVehicleId] = useState('')
    const[showEdit,setShowEdit]=useState(false)
    const token = localStorage.getItem("busAdmin");
    const fetchBuses = (currentPage) =>{
        setLoading(true)
        axios.get(urls.buses+currentPage, { headers: { Authorization: "Bearer " + token } })
        .then((response1) => {
             setLoading(false);
             console.log(response1);
             setBusList(response1.data.results)
             setPageCount(response1.data.count/10)
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const fetchVender = () =>{
        axios.get(urls.vendor, { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
             setVendorList(response2.data.results)
          })
          .catch((error) => {
            console.log(error);
          });
    }
    const fetchRoute = () =>{
        axios.get(urls.listRoutes, { headers: { Authorization: "Bearer " + token } })
        .then((response2) => {
             setRouteList(response2.data.results)
          })
          .catch((error) => {
            console.log(error);
          });

    }
    useEffect(()=>{
        fetchBuses('1')
        fetchVender()
        fetchRoute()

    },[])
    const handleClose = () =>{
        setShowCreate(false)

    }
    const editHandleClose = () =>{
        setShowEdit(false)
    }
    const addBusHandler = () =>{
        setShowCreate(true)
    }
    const BusCloseHanlder = () =>{
        setShowBus(false)

    }
    const handlePageClick = (data) =>{
        fetchBuses(data.selected+1)
     
    }
    const viewHandler = (selVehicle) =>{
        setBusDetail({num:selVehicle.bus_no,regNo:selVehicle.register_no,
                        capacity:selVehicle.seat_capacity,vendor:selVehicle.vendor_id.name,route:selVehicle.route_id.name,
                    model:selVehicle.model,year:selVehicle.year})
        setShowBus(true)
        
    }
    const editShowHandler = (selVehicle) => {
        selVehicleId(selVehicle.id)
        setBusNo(selVehicle.bus_no)
        setRegNo(selVehicle.register_no)
        setCapacity(selVehicle.seat_capacity)
        setRoute(selVehicle.route_id.id)
        setVendor(selVehicle.vendor_id.id)
        setModNo(selVehicle.model)
        setYear(selVehicle.year)
        setShowEdit(true)
    }
   
    const busNoHandler = (e) =>{    setBusNo(e.target.value)    }
    const modNoHandler = (e) =>{    setModNo(e.target.value)    }
    const yearHandler = (e) =>{     setYear(e.target.value)     }
    const capacityHandler = (e)=>{  setCapacity(e.target.value) }
    const regNumHandler = (e)=>{    setRegNo(e.target.value)    }
    const vendorHandler = (e)=> { setVendor(e.target.value) }
    const routeHanlder = (e) =>{ setRoute(e.target.value) }
    const validate = () =>{
        let busNoError;
        let modNoError;
        let yearError;
        let cpacityError;
        let regNumError;
        let vendorError;
        let routeError;
       
        if(!busNo){
          busNoError = 'This field is required'
          setBusNoError(busNoError)
        }
        if(!modNo){
            modNoError = 'This field is required'
          setModNoError(modNoError)
        }
        if(!year){
          yearError = 'This field is required'
          setYearError(yearError)
        }
        if(!capacity){
            cpacityError= 'This field is required'
            setCapacityError(cpacityError)
        }
        if(!regNo){
            regNumError = 'This field is required'
            setRegNumError(regNumError)
        }
        if(!vendor){
            vendorError = 'This field is required'
            setVendorError(vendorError)
        }
        if(!route){
            routeError = 'This field is required'
            setRouteError(routeError)
        }
       
        if(busNoError || modNoError || yearError || cpacityError || regNumError || vendorError || routeError ){
            setBusNoError(busNoError)
            setModNoError(modNoError)
            setYearError(yearError)
            setRegNumError(regNumError)
            setCapacityError(cpacityError)
            setVendorError(vendorError)
            setRouteError(routeError)
          return false
        }
        return true
    
      }
    const createVehicleHandler = (e) =>{
        e.preventDefault()
      
        const isValidate = validate()
        if(isValidate){
            setBusNoError('')
            setModNoError('')
            setYearError('')
            setCapacityError('')
            setVendorError('')
            setRouteError('')
            setRegNumError('')
        const body = {
            bus_no:busNo,
            seat_capacity:capacity,
            register_no:regNo,
            model:modNo,
            year:year,
            vendor_id:vendor,
            route_id:route

        }
        axios.post(urls.createVehicle,body, { headers: { Authorization: "Bearer " + token } })
        .then((response3) => {
            setShowCreate(false)
            setBusNo('')
            setCapacity('')
            setRegNo('')
            setRoute('')
            setYear('')
            setModNo('')
            setVendor('')
            fetchBuses('1')

           console.log(response3);
          })
          .catch((error) => {
            console.log(error);
          });
      
        }
    }
    const editVehicleHandler = () =>{
        const body ={
   
            "bus_no": busNo,
            "seat_capacity": parseInt(capacity),
            "register_no": regNo,
            "model": modNo,
            "brand": "Bmw09",
            "year": parseInt(year),
            "vendor_id": parseInt(vendor),
            "route_id": parseInt(route)
        }
        axios.patch(urls.createVehicle+'/'+vehicleId, body,{ headers: { Authorization: "Bearer " + token } })
        .then((response4) => {
            setShowEdit(false)
            fetchBuses('1')
        })
      .catch((error) => {
        console.log(error);
      });
    }
    let busLists;
    if(loading){
      busLists = (
        <div
          className="d-flex justify-content-center"
          style={{
            paddingTop: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
           
          }}
        >
          <MoonLoader color="#0D2641" />
        </div>
      );
    }
    else{
        busLists = <table className='table' style={{width:'100%',marginLeft:'0px'}}>
        <tr className='firstRow'>
            <th>Vehicle no.</th>
            <th>Registration no.</th>
            <th>Vehicle Capacity</th>
            <th>Vendor</th>
            <th>Route</th>
            <th>Action</th>
        </tr>
        <thead></thead>
        <tbody>
            {busList.map((item,index)=>{
                return(
                    <tr className='rowItems' key={index}>
                        <td>{item.bus_no}</td>
                        <td>{item.register_no}</td>
                        <td>{item.seat_capacity}</td>
                        <td>{item.vendor_id.name}</td>
                        <td>{item.route_id.name}</td>
                        
                        <td><AiOutlineExclamationCircle color='#33BBE0' style={{cursor:'pointer'}} size={25} onClick={()=>viewHandler(item)}/>
                        <TbMinusVertical color='#E0E2E7' size={25}/>
                        <BiEdit color='#0D77BD' size={25} style={{cursor:'pointer'}} onClick={()=>editShowHandler(item)}/>
                        </td>
                    </tr>
                )
            })}
                           
        </tbody>
        <tfoot></tfoot>
        </table>
    }
  return (
    <DashboardLayout selectKey={['10']}>
           < DetailModal showDet={showBus} detHandleClose={BusCloseHanlder}>
                               
                                    <div className='modalCont'>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Vehicle no.</h5>
                                        <span>{busDetail.num}</span>

                                        </div>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Registration no.</h5>
                                        <span>{busDetail.regNo}</span>

                                        </div>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Model no.</h5>
                                        <span>{busDetail.model}</span>

                                        </div>
                                        <div style={{paddingBottom:'15px'}}>
                                        <h5>Year of manufacturing</h5>
                                        <span>{busDetail.year}</span>

                                        </div>
                                        <div style={{paddingBottom:'15px'}}>
                                            <h5>Vehicle Capacity</h5>
                                            <span>{busDetail.capacity}</span>
                                        </div>
                                        <div style={{paddingBottom:'15px'}}>
                                                <h5>Vendor </h5>
                                                <span>{busDetail.vendor} </span>
                                        </div>
                                     
                                        <div >
                                                <h5>Route </h5>
                                                <span>{busDetail.route}</span>
                                        </div>
                                       
                                        
                                    </div>
                    </DetailModal>
         <CreateStaff title='Create Vehicle'  onHide={handleClose} show={showCreate}>
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Bus number</label><br/>
                        <input type="text" className='inputText' placeholder='Enter Bus number' onChange={busNoHandler} value={busNo}/>
                        <div className="ErrorMsg">{busNoError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <label className='label'>Model no.</label><br/>
                        <input type="text" className='inputText' onChange={modNoHandler} value={modNo}/>
                        <div className="ErrorMsg">{modNoError}</div>
                    </div>
                    <div className='col-md-4'>
                        <label className='label'> Manufacturing year</label><br/>
                        <input type="text" className='inputText'  onChange={yearHandler} value={year}/>
                        <div className="ErrorMsg">{yearError}</div>
                    </div>
                    <div className='col-md-4'>
                        <label className='label'>Vehicle Capacity</label><br/>
                        <input type="text" className='inputText' onChange={capacityHandler} value={capacity}/>
                        <div className="ErrorMsg">{capacityError}</div>
                    </div>
                </div>
               <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Registration number</label><br/>
                        <input type="text" className='inputText' onChange={regNumHandler} value={regNo}/>
                        <div className="ErrorMsg">{regNumError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Vendor</label><br/>
                            <select className='selectText' onChange={vendorHandler} >
                                <option value="0" disabled>Choose Vendor</option>
                                {vendorList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>

                                    )
                                })}
                             </select>
                             <div className="ErrorMsg">{vendorError}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Route</label><br/>
                            <select className='selectText' onChange={routeHanlder}>
                                <option value="0" disabled>Choose Route</option>
                                {routeList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>

                                    )
                                })}
                               
                            </select>
                            <div className="ErrorMsg">{routeError}</div>
                    </div>
                </div>
                <div className='submit' onClick={createVehicleHandler}>SUBMIT</div>

                </form>
              

            </CreateStaff>
            <CreateStaff title='Edit Vehicle'  onHide={editHandleClose} show={showEdit}>
            <form>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Bus number</label><br/>
                        <input type="text" className='inputText' placeholder='Enter Bus number' onChange={busNoHandler} value={busNo}/>
                      
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <label className='label'>Model no.</label><br/>
                        <input type="text" className='inputText' onChange={modNoHandler} value={modNo}/>
                       
                    </div>
                    <div className='col-md-4'>
                        <label className='label'> Manufacturing year</label><br/>
                        <input type="text" className='inputText'  onChange={yearHandler} value={year}/>
                       
                    </div>
                    <div className='col-md-4'>
                        <label className='label'>Vehicle Capacity</label><br/>
                        <input type="text" className='inputText' onChange={capacityHandler} value={capacity}/>
                       
                    </div>
                </div>
               <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Registration number</label><br/>
                        <input type="text" className='inputText' onChange={regNumHandler} value={regNo}/>
                      
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Vendor</label><br/>
                            <select className='selectText' onChange={vendorHandler} >
                                <option value="0" disabled>Choose Vendor</option>
                                {vendorList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>

                                    )
                                })}
                             </select>
                            
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <label className='label'>Route</label><br/>
                            <select className='selectText' onChange={routeHanlder}>
                                <option value="0" disabled>Choose Route</option>
                                {routeList.map((item,index)=>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>

                                    )
                                })}
                               
                            </select>
                           
                    </div>
                </div>
                <div className='submit' onClick={editVehicleHandler}>SUBMIT</div>

                </form>
              

            </CreateStaff>
         <div className='Dashboard'>
                <div className='selectBox'>
                    <h3 className='Head'>Vehicles</h3>
                    <div className='addStaff' onClick={addBusHandler}>ADD</div>
                 </div>
                        {busLists}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< prev"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination justify-content-end pageout'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                />
        </div>
        </DashboardLayout>
  )
}
