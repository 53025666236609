import React, { useState, useEffect } from 'react'
import DashboardLayout from "../components/DashboardLayout/DashboardLayout"
import CreateStaff from '../components/DetailModal/CreateStaff'
import DetailModal from '../components/DetailModal/DetailModal'
import axios from 'axios'
import * as urls from '../Urls'
import MoonLoader from "react-spinners/MoonLoader"
import ReactPaginate from "react-paginate";
import { MdInfoOutline } from 'react-icons/md'

export default function MasterNotification() {
  const token = localStorage.getItem("busAdmin")
  const [notiShow, setNotiShow] = useState(false)
  const [showNoti, setShowNoti] = useState(false)
  const [busList, setBusList] = useState([])
  const [loading, setLoading] = useState(false)
  const [newBusList, setNewBusList] = useState([])
  const [staffList, setStaffList] = useState([])
  const [message, setMessage] = useState([])
  const [messageError, setMessageError] = useState('')
  const [staff,setStaff] = useState('')
  const [vehicle,setVehicle] = useState('')
  const[pageCount,setPageCount] = useState('')
  const [staffError, setStaffError] = useState('');
const [vehicleError, setVehicleError] = useState('');

  const [messagedet,setMessageDet] = useState({
    created_on:"",
    bus_no:"",
    staff: "",
    notif:""
  })
  
    const  fetchBusMessages = (currentPage)=>{
      setLoading(true)
    axios.get(urls.listBusMessages  + currentPage, { headers: { Authorization: "Bearer " + token } })
      .then((response1) => {
        setBusList(response1.data.results);
        console.log(response1);
        setPageCount(response1.data.count/10)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    axios.get(urls.busStaf, { headers: { Authorization: "Bearer " + token } })
      .then((response2) => {
        console.log('2', response2);
        // let newStaffArray = [];
        // for (let i = 0; i < response2.data.results.length; i++) {
        //   newStaffArray.push({
        //     name: response2.data.results[i].name,
        //     id: response2.data.results[i].id,
        //   });
        // }
        // console.log(newStaffArray)
        setStaffList(response2.data.results)
      })
      .catch((error) => {
        console.log(error);
      })
    axios.get(urls.buses, { headers: { Authorization: "Bearer " + token } })
      .then((response3) => {
        console.log('3', response3);
        // let newBusArray = [];
        // for (let i = 0; i < response3.data.results.length; i++) {
        //   newBusArray.push({
        //     number: response3.data.results[i].bus_no,
        //     id: response3.data.results[i].id
        //   });
        // }
        // console.log(newBusArray)
        setNewBusList( response3.data.results)
      })
      .catch((error) => {
        console.log(error);
      })
    }
  
useEffect(() => {
  fetchBusMessages('1')
}, [showNoti,notiShow]);

const handlePageClick = (data) =>{
  fetchBusMessages(data.selected+1)


}
 
const createHandler = () => {
  setNotiShow(false);
  setVehicle('')
          setStaff('')
          setMessage('')
  let isValid = true;

  // Validate staff option field
  if (!staff) {
    setStaffError('This field is required');
    isValid = false;
  } else {
    setStaffError('');
  }

  // Validate vehicle option field
  if (!vehicle) {
    setVehicleError('This field is required');
    isValid = false;
  } else {
    setVehicleError('');
  }

  // Validate message text area
  if (!message) {
    setMessageError('This field is required');
    isValid = false;
  } else {
    setMessageError('');
  }

  if (isValid) {
      const body = {
        message: message,
        bus_staff_id: staff,
        vehicle_id: vehicle

        

      }
      console.log(body)
     var form_data = new FormData()
     for ( var key in body ) {
      form_data.append(key, body[key]);
  }
      axios
        .post(urls.listBusMessages, form_data, { headers: { Authorization: "Bearer " + token } })
        .then((response4) => {
          console.log(response4)
         

        })
        .catch((error) => {
          console.log(error);
        });

    }
  }
  const viewMessageHandler = (msg) => {
    console.log(msg);
    setShowNoti(true);
    setMessageDet({
      created_on: msg.created_at,
      bus_no: msg.vehicle_id.bus_no,
      staff: msg.bus_staff_id.name,
      notif:msg.message
    });
  };
  const handleClose = () => {
    setNotiShow(false)
    setStaff('')
    setMessageError('')
    setStaffError('')
    setVehicleError('')
    setVehicle('')
    setMessage('')
  }
  const messageHandler = (e) => {
    setMessage(e.target.value)
  }
  const addRouteHandler = () => {
    setNotiShow(true)

  }
  const notiCloseHanlder = () => {
    setShowNoti(false)

  }
  const staffChangeHandler = (e) => {
    setStaff(e.target.value);
  }
  const vehicleChangeHandler = (e) => {
    setVehicle(e.target.value);
  }
  
   
  let busMessages;
  if(loading){
    busMessages = (
      <div
        className="d-flex justify-content-center"
        style={{
          paddingTop: "250px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
         
        }}
      >
        <MoonLoader color="#0D2641" />
      </div>
    );
  }
  else{
    busMessages = (
      <table className="table" style={{ width: '100%', marginLeft: '0px' }}>
        <tr className="firstRow ">
          <th>Date</th>
          <th>Message</th>
          <th>Bus no.</th>
          <th>Bus staff</th>
          <th className="action">Action</th>

        </tr>
        {busList.map((item, index) => {
  const date = new Date(item.created_at);
  const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours() % 12 || 12}:${date.getMinutes()} ${date.getHours() >= 12 ? 'pm' : 'am'}`;
  return (
    <tr className="rowItems " key={index}>
      <td>{formattedDate}</td>
      <td>{item.message}</td>
      <td>{item.vehicle_id.bus_no}</td>
      <td>{item.bus_staff_id.name}</td>

      <td className="action">
        <MdInfoOutline size={35} color='#33BBE0' style={{ cursor: "pointer" }} onClick={() => viewMessageHandler(item)} />
      </td>
    </tr>
  );
})}

      </table>
    )
  }

  return (
    <DashboardLayout selectKey={["13"]}>
      < DetailModal showDet={showNoti} detHandleClose={notiCloseHanlder}>

        <div className='modalCont'>
          <div style={{ paddingBottom: '15px' }}>
            <h5>Created on</h5>
            <span>{new Date(messagedet.created_on).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}</span>

          </div>
          <div style={{ paddingBottom: '15px' }}>
            <h5>Bus number</h5>
            <span>{messagedet.bus_no}</span>

          </div>
          <div style={{ paddingBottom: '15px' }}>
            <h5>Staff</h5>
            <span>{messagedet.staff}</span>

          </div>
          <div style={{ paddingBottom: '15px' }}>
            <h5>Message</h5>
            <span>{messagedet.notif}</span>

          </div>



        </div>
      </DetailModal>
      <CreateStaff title='Create Notification' onHide={handleClose} show={notiShow}>

        <form>
          <div className='row'>
            <div className='col-md-12'>
              <label className='label'>Bus</label><br />
              <select value={vehicle} onChange={vehicleChangeHandler} className='selectText'>
                <option value="0">Choose Bus</option>
                {newBusList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>{item.bus_no}</option>
                  )
                })}
              </select>
              {vehicleError && <div className="ErrorMsg">{vehicleError}</div>}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <label className='label'>Bus Staff</label><br />
              <select value={staff} onChange={staffChangeHandler} className='selectText'>
                <option >Choose Staff</option>
                {staffList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>{item.name}</option>
                  )
                })}

              </select>
              {staffError && <div className="ErrorMsg">{staffError}</div>}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <label className='label'>Message</label><br />

              <textarea value={message} onChange={messageHandler} rows="4" cols="59"></textarea>
             {messageError && <div className="ErrorMsg">{messageError}</div>}

            </div>
            
          </div>
          <div className='submit' onClick={createHandler}>CREATE</div>
        </form>
      </CreateStaff>
      <div className="Dashboard">
        <div className="selectBox">
          <h3 className="Head">Notification</h3>
          <div className="addStaff" onClick={addRouteHandler}>CREATE</div>
        </div>

      </div>
      {busMessages}

      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        marginPagesDisplayed={3}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        containerClassName={'pagination justify-content-end pageout'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </DashboardLayout>
  )
}
