import React, { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { Select } from "antd";
import axios from "axios";
import { DatePicker, Space, Input } from "antd";
import { AiOutlineFilter } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { useHistory } from "react-router-dom";
// import { MdArrowDropDown } from 'react-icons/md'
import MoonLoader from "react-spinners/MoonLoader";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Confirmation from "../components/DetailModal/ChangeRole";
import DetailModal from "../components/DetailModal/DetailModal";
// import profile from '../assets/images/profile.png'
import CreateStaff from "../components/DetailModal/CreateStaff";
import ReactFlagsSelect from "react-flags-select";
import * as urls from "../Urls";
import * as country from "../countryList";
import ReactPaginate from "react-paginate";
import Button from '@material-ui/core/Button';
import defImg from '../assets/images/dumy_propic.png'

const MasterStudentList = () => {
  const [show, setShow] = useState(false);
  const [showStudent, setShowStudent] = useState(false);
  const [classList, setClassList] = useState([]);
  const [routList, setRouteList] = useState([]);
  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState("");
  const [clas, setClas] = useState("");
  const [parName, setParName] = useState("");
  const [selClas,setSelClas] = useState('')
  const [parNum, setParNum] = useState("");
  const [blood, setBlood] = useState("");
  const [routeArray, setRouteArray] = useState([]);
  const [address, setAddress] = useState("");
  const [selectStudId, setSelectStudId] = useState("");
  const [selected, setSelected] = useState("QA");
  const [studentDet, setStudentDet] = useState({
    name: "",
    admnNo: "",
    img: "",
    clas: "",
    loc: "",
    parName: "",
    contct: "",
    bldGrp:'',
    route:'',
    date:'',
    id:''
  });
  const [search, setSearch] = useState("");
  const[nameError,setNameError] = useState('')
  const[parnameError,setParNameError] = useState('')
  const[phoneNumberError,setPhoneNumberError] = useState('')
  const[bloodGroupError,setBloodGroupError] = useState('')
  const[classError,setClassError] = useState('')
  const[adIdError,setAdIdError] = useState('')
  const[addressError,setAddressError] = useState('')
  const[routeError,setRouteError] = useState('')
  const[pageCount,setPageCount] = useState('')
  const[lattitude,setLattitude] = useState('')
  const[selStaus,setSelStatus] = useState('')
  const[longitude,setLongitude] = useState('')
  const[lattitudeError,setLattitudeError] = useState('')
  const[longitudeError,setLongitudeError] = useState('')
  const[selRoute,setSelRoute] = useState('')
  const[classArray,setClassArray] = useState([])
  const[filtShow,setFiltShow] = useState(false)
  const[error,setError] = useState('')
  const[propImg,setProImg] = useState(defImg)
  const[proUrl,setproUrl] = useState(null)
  const history = useHistory();

  const token = localStorage.getItem("busAdmin");
  useEffect(() => {
    axios
      .get(urls.listClasses, { headers: { Authorization: "Bearer " + token } })
      .then((response1) => {
        setClassList(response1.data.results);
        let newClassArray=[];
        for (let i = 0; i < response1.data.results.length; i++) {
          newClassArray.push({
            value: response1.data.results[i].id,
            label: response1.data.results[i].class_name+response1.data.results[i].division,
          });
        }
        setClassArray(newClassArray)

        console.log(response1);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(urls.listRoutes, { headers: { Authorization: "Bearer " + token } })
      .then((response2) => {
        setRouteList(response2.data.results);
        let newRouteArray = [];
        for (let i = 0; i < response2.data.results.length; i++) {
          newRouteArray.push({
            value: response2.data.results[i].id,
            label: response2.data.results[i].name,
          });
        }
        setRouteArray(newRouteArray);
        console.log(newRouteArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const  fetchStudent = (currentPage)=>{
    setLoading(true);
    axios.get(urls.listStudent+currentPage, { headers: { Authorization: "Bearer " + token } })
        .then((response3) => {
              setStudentList(response3.data.results);
              setPageCount(response3.data.count/10)
              setLoading(false);

              console.log(response3);
          })
          .catch((error) => {
            console.log(error);
          });
    } 
  useEffect(() => {
     fetchStudent('1')
  }, [addShow, editShow]);

  const confirmCloseHanlder = () => setShow(false);
  const filterRoute = (curentPage,val) =>{
    setLoading(true);
    axios
      .get(urls.listStudent + curentPage+"&route_id=" + val, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response5) => {
        setLoading(false);
        if (!(response5.data == "")) {
          setStudentList(response5.data.results);
          setPageCount(response5.data.count/10)
        } else {
          setStudentList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const filterClass = (curntPage,val) =>{
    setLoading(true);
    axios
      .get(urls.listStudent + curntPage+"&class_id=" + val, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response6) => {
        setLoading(false);
        if (!(response6.data == "")) {
          setStudentList(response6.data.results);
        } else{
          setStudentList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const filterStatus = (curntPage,val) =>{
    setLoading(true)
    axios
    .get(urls.listStudent + curntPage+"&is_active=" + val, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response6) => {
      setLoading(false);
      if (!(response6.data == "")) {
        setStudentList(response6.data.results);
      } else{
        setStudentList([]);
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }
  const fileterName = (curntPage,val) =>{
    setLoading(true)
    axios
    .get(urls.listStudent + curntPage+"&name=" + val, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response6) => {
      setLoading(false);
      setError('')
      if (!(response6.data == "")) {
        setStudentList(response6.data.results);
      } else{
        setStudentList([]);
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }
  const imageUploadHandler = (e) =>{
    const reader = new FileReader()
    reader.onload = () => {
      if(reader.readyState === 2){
        setProImg(reader.result)
      }
    } 
    reader.readAsDataURL(e.target.files[0])
    setproUrl(e.target.files[0])

  } 
   const searchHandler = () =>{
    if(search.length!==0){
      fileterName('1',search)

    }
    else{
      setError('Please enter a keyword or phrase to start your search')
    }
   

  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelRoute(value)
    filterRoute('1',value)
  };
  const classChange = (value) =>{
     setSelClas(value)
     filterClass('1',value)
  }
  const statusChange = (value) =>{
    setSelStatus(value)
    filterStatus('1',value)
   

  }
  //   const onStartDateChange = (date, dateString) => {
  //     console.log(date, dateString);
  //   };
  //   const onEndDateChange = (date, dateString) => {
  //     console.log(date, dateString);
  //   };
  //   const exportHandler = () =>{
  //     setShow(true)

  //   }
  const studentCloseHanlder = () => {
    setShowStudent(false);
  };
  const viewStudentHandler = (student) => {
    console.log(student);
    setShowStudent(true);
    setStudentDet({
      name: student.name,
      admnNo: student.admission_number,
      img: student.image,
      clas: student.class_id.class + student.class_id.division,
      loc: student.locationtext,
      parName: student.parent_id.name,
      contct: student.parent_id.phone_code + student.parent_id.phone_number,
      bldGrp:student.blood_group,
      route:student.route_id.name,
      date:student.created_at,
      id:student.id
    });
  };
  const viewHistoryHandler = () => {
    history.push("/studentHistory");
  };
  const addStudentClose = () => {
    setAddShow(false);
  };
  const addStudentHandler = () => {
    setAddShow(true);
  };
  const searchChangeHandler = (e) => {
    setSearch(e.target.value);
  };
  const handlePageClick = (data) =>{
    fetchStudent(data.selected+1)
    filterRoute(data.selected+1,selRoute)
    filterClass(data.selected+1,selClas)
    filterStatus(data.selected+1,selStaus)
    fileterName(data.selected+1,search)
  

  }
  const filterShowHandler = () =>{
    setFiltShow(!filtShow)
  }

  const editStudentClose = () => {
    setEditShow(false);
    setName("");
    setAge("");
    setAddress("");
    setBlood("");
    setClas("");
    setId("");
    setClas("");
    setRoute("");
    setParName("");
    setParNum("");
    setProImg(defImg)
  };
  const editStudentShow = (studentDets) => {
    console.log(studentDets);
    setEditShow(true);
    setName(studentDets.name);
    setId(studentDets.admission_number);
    setAddress(studentDets.address);
    setSelectStudId(studentDets.id);
    setClas(studentDets.class_id.id);
    setRoute(studentDets.route_id.id);
    setProImg("https://bus.goldzin.com/" + studentDets.image)
    setBlood(studentDets.blood_group);
    setParName(studentDets.parent_id.name);
    setParNum(
      studentDets.parent_id.phone_number
    );
    console.log(studentDets);
  };
  const viewIdHanlder = (studentdet) => {
    history.push({pathname:"/viewId",state:{data:studentdet}});
  };
  const nameHnalder = (e) => {
    setName(e.target.value);
  };
  const studentIdHandler = (e) => {
    setId(e.target.value);
  };
  //   const ageChangeHandler = (e) =>{ setAge(e.target.value)  }
  const classChangeHandler = (e) => {
    setClas(e.target.value);
  };
  const parNameChangeHandler = (e) => {
    setParName(e.target.value);
  };
  const parentNumChangeHandler = (e) => {
    setParNum(e.target.value);
  };
  const bloodChangeHandler = (e) => {
    setBlood(e.target.value);
  };
  const routeChangeHandler = (e) => {
    setRoute(e.target.value);
  };
  const addressHandler = (e) => {
    setAddress(e.target.value);
  };
  const lattutudeHandlder = (e) =>{
    setLattitude(e.target.value)
  }
  const longitudeHandleChange = (e) =>{
    setLongitude(e.target.value)
  }
  const validate = () =>{
    let nameError;
    let parnameError;
    let phoneNumberError;
    let bloodGroupError;
    let classError;
    let adIdError;
    let routeError;
    let addressError;
    let lattitudeError;
    let longitudeError;
    if(!name){
      nameError = 'This field is required'
      setNameError(nameError)
    }
    if(!parName){
      parnameError = 'This field is required'
      setParNameError(parnameError)
    }
    if(!parNum){
      phoneNumberError = 'This field is required'
      setPhoneNumberError(phoneNumberError)
    }
    if(!blood){
      bloodGroupError = 'This field is required'
      setBloodGroupError(bloodGroupError)
    }
    if(!clas){
      classError = 'This field is required'
      setClassError(classError)
     
    }
    if(!id){
      adIdError = 'This field is required'
      setAdIdError(adIdError)
    }
    if(!route){
      routeError = 'This field is required'
      setRouteError(routeError)
    }
    if(!address){
      addressError = 'This field is required'
      setAddressError(addressError)
    }
    if(!lattitude){
      lattitudeError = 'This field is required'
      setLattitudeError(lattitudeError)
    }
    if(!longitude){
      longitudeError = 'This field is required'
      setLongitudeError(longitudeError)
    }
    if(nameError || addressError || routeError || adIdError || classError || bloodGroupError || parnameError || phoneNumberError || lattitudeError || longitudeError){
      setNameError(nameError)
      setAdIdError(adIdError)
      setAddressError(addressError)
      setParNameError(parnameError)
      setRouteError(routeError)
      setClassError(classError)
      setBloodGroupError(bloodGroupError)
      setLattitudeError(lattitudeError)
      setLongitudeError(longitudeError)
    
      return false
    }
    return true

  }
  const createStudentHandler = (e) => {
    e.preventDefault();
    const isValidate = validate()
    if(isValidate){
      setNameError('')
      setRouteError('')
      setAddressError('')
      setClassError('')
      setAdIdError('')
      setBloodGroupError('')
      setParNameError('')
      setPhoneNumberError('')
      setLongitudeError('')
      setLattitudeError('')
      let selObj = country.arrayCountryList.find(
        (item) => item.name === selected
      );
      const body = {
        name: name,
        parent_name: parName,
        phone_code: selObj.code,
        phone_number: parNum,
        blood_group: blood,
        class_id: clas,
        admission_number: id,
        route_id: route,
        address: address,
        latitude:lattitude,
        longitude:longitude
      };
      const fd = new FormData()
      fd.append('image',proUrl,proUrl.name)
      
      axios
        .post(urls.createStudent, body, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response3) => {
          console.log(response3)
          axios
          .post(urls.proImg+response3.data.data.id+'/image', fd, {
            headers: { Authorization: "Bearer " + token },
          })
          .then((response4) => {
            addStudentClose()
            console.log(response4);
          })
          .catch((error) => {
            console.log(error);
          });
         
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(body);

    }

   
  };
  const statusChangeHandler = (status,studentId) =>{
    const body = {
      is_active:!status

    }
    axios.patch(urls.editStudent + studentId, body, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response7) => {
        setLoading(true);
        fetchStudent('1')
    })
    .catch((error) => {
      console.log(error);
    });

  }
  const editStudentHandler = (e) => {
    e.preventDefault();
    let selObj = country.arrayCountryList.find(
      (item) => item.name === selected
    );
    const body = {
      name: name,
      address: address,
      phone_code: selObj.code,
      phone_number: parNum,
      class_id: clas,
      route_id:route,
      parent_name: parName,
      blood_group: blood,
      admission_number: id,
      latitude:lattitude,
      longitude:longitude
    };
    const fd = new FormData()
    fd.append('image',proUrl,proUrl.name)
    console.log(body);
    axios
      .patch(urls.editStudent + selectStudId, body, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response6) => {
        axios
        .patch(urls.proImg+selectStudId, fd, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response4) => {
          editStudentClose()
          console.log(response4);
        })
        .catch((error) => {
          console.log(error);
        });
       

     

        console.log(response6);
      })
      .catch((error) => {
        console.log(error);
      });
   
  };
  const removepicHandler = () =>{
    setProImg(defImg)
    setproUrl(null)

  }
  

  let students;
  if (loading) {
    students = (
      <div
        className="d-flex justify-content-center"
        style={{
          paddingTop: "250px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <MoonLoader color="#0D2641" />
      </div>
    );
  } else{
    students = (
      <table className="table" style={{marginLeft:'20px'}}>
        <tr className="firstRow">
          <th>Sl no.</th>
          <th>Name</th>
          <th>ADM NO</th>
          <th>Route</th>
          <th>Class</th>
          <th>Pickup Location</th>
          <th>Parent Contact</th>
          <th>Status</th>
          <th>Action&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
        </tr>
        {studentList.map((item, index) => {
          return (
            <tr className="rowItems" key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.admission_number}</td>
              <td>{item.route_id.name}</td>
              <td>{item.class_id.class + item.class_id.division}</td>
              <td>{item.locationtext}</td>
              <td>{item.parent_id.phone_code + item.parent_id.phone_number}</td>
              <td>
                <div className={item.is_active?"enable":"disable"}>{item.is_active?'Enabled':'Disabled'}</div>
              </td>
              <td className="statusTd">
                <BiDotsVerticalRounded color="#868686" size={38} />
                <div className="statusDropDown">
                  <div className="firstDrop">
                    <span onClick={() => viewStudentHandler(item)}>
                      View Student Details
                    </span>
                  </div>
                  <div className="firstDrop">
                    <span onClick={() => editStudentShow(item)}>
                      Edit Student Details
                    </span>
                  </div>
                  <div className="firstDrop">
                    <span onClick={viewHistoryHandler}>View History</span>
                  </div>

                  <div className="firstDrop">
                    <span style={{ color: "#FF6060" }} onClick={()=>statusChangeHandler(item.is_active,item.id)}>{item.is_active?"Disable":"Enable"}</span>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    );
  }

  return (
    <DashboardLayout selectKey={["9"]}>
      <Confirmation
        body="Do you want to export file for updation of information?"
        shows={show}
        handleClose={confirmCloseHanlder}
      />
      <DetailModal showDet={showStudent} detHandleClose={studentCloseHanlder}>
        <div className="d-flex">
          <img
            src={"https://bus.goldzin.com/" + studentDet.img}
            width="50px"
            height="50px"
            className="studnt"
            alt="prof"
          />
          <div className="profile">
            <h5>{studentDet.name}</h5>
            <h6>{studentDet.admnNo}</h6>
          </div>
        </div>
        <div className="modalCont">
          {/* <div  style={{paddingBottom:'15px'}}>
                                        <h5>Age</h5>
                                        <span>12</span>

                                        </div>
                                        <div  style={{paddingBottom:'15px'}}>
                                        <h5>Date of Birth</h5>
                                        <span>12/12/2002</span>

                                        </div> */}
          <div style={{ paddingBottom: "15px" }}>
            <h5>Class</h5>
            <span>{studentDet.clas}</span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <h5>Pickup Location</h5>
            <span>{studentDet.loc}</span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <h5>Parent details</h5>
            <span>{studentDet.parName}</span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <h5>Emergency Contact </h5>
            <span>{studentDet.contct} </span>
          </div>
          <div style={{ paddingBottom: "15px" }}>
            <p
              className="idCard"
              onClick={()=>viewIdHanlder(studentDet)}
              style={{ cursor: "pointer" }}
            >
              View ID Card Details
            </p>
          </div>
        </div>
      </DetailModal>
      <CreateStaff
        title="Create Student Details"
        onHide={addStudentClose}
        show={addShow}
      >
        <form onSubmit={createStudentHandler}>
        <div className="d-flex">
          <img src={propImg} alt='defimg' className="propic"/>
        <>
  <input
    accept="image/*"
    type="file"
    id="select-image"
    onChange={imageUploadHandler}
  style={{ display: 'none' }}
  />
  <label htmlFor="select-image">
    <Button variant="contained" color="primary" component="span" className="changBtn">
      Change
    </Button>
  </label>
  <label className="remove" onClick={removepicHandler}>Remove</label>
</>

          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Student name</label>
              <br />
              <input
                type="text"
                className="inputText"
                placeholder="Enter your name"
                onChange={nameHnalder}
                value={name}
              />
              <div className="ErrorMsg">{nameError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">ADM NO</label>
              <br />
              <input
                type="text"
                className="inputText"
                onChange={studentIdHandler}
                value={id}
              />
              <div className="ErrorMsg">{adIdError}</div>
            </div>
          </div>
          <div className="row">
            {/* <div className='col-md-4'>
                                            <label className='label'>Age</label><br/>
                                            <input type="text" className='inputText' onChange={ageChangeHandler} value={age}/>
                                        </div> */}
            {/* <div className='col-md-5'>
                                            <label className='label'>Date of Birth</label><br/>
                                            <input type="date" className='inputText' placeholder='Choose Date' value={dob}/>
                                            
                                        </div> */}
            <div className="col-md-7">
              <label className="label">Class</label>
              <br />
              <select
                className="selectText"
                onChange={classChangeHandler}
                value={clas}
              >
                <option value="0">Select Class</option>
                {classList.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.class_division}
                    </option>
                  );
                })}
              </select>
              <div className="ErrorMsg">{classError}</div>
            </div>
            <div className="col-md-5">
              <label className="label">Route</label>
              <br />
              <select
                className="selectText"
                onChange={routeChangeHandler}
                value={route}
              >
                <option value="0">Select Route</option>
                {routList.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
               
              </select>
              <div className="ErrorMsg">{routeError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Address</label>
              <br />
              <textarea
                className="inputText"
                value={address}
                onChange={addressHandler}
              />
              <div className="ErrorMsg">{addressError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="label">Latitude</label>
              <br />
              <input
                type="text"
                className="inputText"
                placeholder="Enter lattitude"
                onChange={lattutudeHandlder}
                value={lattitude}
              />
              <div className="ErrorMsg">{lattitudeError}</div>
            </div>
            <div className="col-md-6">
              <label className="label">Longitude</label>
              <br />
              <input
                type="text"
                className="inputText"
                placeholder="Enter longitude"
                onChange={longitudeHandleChange}
                value={longitude}
              />
              <div className="ErrorMsg">{longitudeError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Blood Group</label>
              <br />
              <select
                className="selectText"
                onChange={bloodChangeHandler}
                value={blood}
              >
                <option value="0">Select Blood Group</option>
                <option value="A+">A positive</option>
                <option value="A-">A negative</option>
                <option value="B+">B positive</option>
                <option value="B-">B negative</option>
                <option value="O+">O positive</option>
                <option value="AB+">AB positive</option>
                <option value="AB-">AB negative</option>
              </select>
              <div className="ErrorMsg">{bloodGroupError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Parent details</label>
              <br />
              <div className="row">
                <div className="col-md-4">
                  <input
                    type="text"
                    className="inputText"
                    placeholder="Enter Parent name"
                    onChange={parNameChangeHandler}
                    value={parName}
                  />
                  <div className="ErrorMsg">{parnameError}</div>
                </div>

                <div className="col-md-8">
                  <div className="d-flex">
                    <ReactFlagsSelect
                      selected={selected}
                     countries={country.countryList}
                      customLabels={country.countryCode}
                      className="menu-flags"
                      selectedSize={14}
                      fullWidth={false}
                      onSelect={(code) => setSelected(code)}
                    />
                    <input
                      type="text"
                      className="inputText"
                      placeholder="Enter Number"
                      onChange={parentNumChangeHandler}
                      value={parNum}
                    />
                   
                  </div>
                  <div className="ErrorMsg">{phoneNumberError}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit" onClick={createStudentHandler}>
            CREATE
          </div>
        </form>
      </CreateStaff>
      <CreateStaff
        title="Edit Student Details"
        onHide={editStudentClose}
        show={editShow}
      >
        <form>
        <div className="d-flex">
          <img src={propImg} alt='defimg' className="propic"/>
        <>
  <input
    accept="image/*"
    type="file"
    id="select-image"
    onChange={imageUploadHandler}
  style={{ display: 'none' }}
  />
  <label htmlFor="select-image">
    <Button variant="contained" color="primary" component="span" className="changBtn">
      Change
    </Button>
  </label>
  <label className="remove" onClick={removepicHandler}>Remove</label>
</>

          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Student name</label>
              <br />
              <input
                type="text"
                className="inputText"
                placeholder="Enter your name"
                onChange={nameHnalder}
                value={name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">ADM NO</label>
              <br />
              <input
                type="text"
                className="inputText"
                onChange={studentIdHandler}
                value={id}
              />
            </div>
          </div>
          <div className="row">
            {/* <div className='col-md-4'>
                                            <label className='label'>Age</label><br/>
                                            <input type="text" className='inputText' onChange={ageChangeHandler} value={age}/>
                                        </div> */}
            {/* <div className='col-md-5'>
                                            <label className='label'>Date of Birth</label><br/>
                                            <input type="date" className='inputText' placeholder='Choose Date' value={dob}/>
                                            
                                        </div> */}
            <div className="col-md-7">
              <label className="label">Class</label>
              <br />
              <select
                className="selectText"
                onChange={classChangeHandler}
                value={clas}
              >
                <option value="0">{clas}</option>
               
                {classList.map((item,index)=>{
                                                        return(
                                                            <option value={item.id} key={index}>{item.class_division}</option>

                                                        )
                                                    })}
              </select>
            </div>
            <div className="col-md-5">
              <label className="label">Route</label>
              <br />
              <select
                className="selectText"
                onChange={routeChangeHandler}
                value={route}
              >
                <option value="0">{route}</option>
                {routList.map((item,index)=>{
                                                        return(
                                                            <option value={item.id} key={index}>{item.name}</option>

                                                        )
                                                    })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Address</label>
              <br />
              <textarea
                className="inputText"
                value={address}
                onChange={addressHandler}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Blood Group</label>
              <br />
              <select
                className="selectText"
                onChange={bloodChangeHandler}
                value={blood}
              >
                <option value="0">{blood}</option>
                <option value="A+">A positive</option>
                <option value="A-">A negative</option>
                <option value="B+">B positive</option>
                <option value="B-">B negative</option>
                <option value="O+">O positive</option>
                <option value="AB+">AB positive</option>
                <option value="AB-">AB negative</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="label">Parent details</label>
              <br />
              <div className="row">
                <div className="col-md-4">
                  <input
                    type="text"
                    className="inputText"
                    placeholder="Enter Parent name"
                    onChange={parNameChangeHandler}
                    value={parName}
                  />
                </div>

                <div className="col-md-8">
                  <div className="d-flex">
                  <ReactFlagsSelect
                      selected={selected}
                     countries={country.countryList}
                      customLabels={country.countryCode}
                      className="menu-flags"
                      selectedSize={14}
                      fullWidth={false}
                      onSelect={(code) => setSelected(code)}
                    />
                     <input
                        type="text"
                        className="inputText"
                        placeholder="Enter Number"
                        onChange={parentNumChangeHandler}
                        value={parNum}
                  />
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="submit" onClick={editStudentHandler}>
            SUBMIT
          </div>
        </form>
      </CreateStaff>
      <div className="Dashboard">
        <div className="selectBox">
          <h3 className="Head">Students</h3>
          <div className="d-flex">
            <div className='filterBox' onClick={filterShowHandler}>< AiOutlineFilter color='#0D77BD' size={25}/> Filter</div>
                    {/* <div className='importBox'> MANAGE< MdArrowDropDown color='#FFFFFF' size={35} style={{marginTop:'-3px'}}/>
                        <div class="dropdown-content">
                            <div className='firstDrop'><span >Import</span></div>
                            <div className='secondDrop'><span >Import for updation</span></div>
                            <div className='firstDrop' onClick={exportHandler}><span >Export</span></div>
                            <div className='secondDrop'><span >Export for updation</span></div>
                        </div>
                 </div> */}
            <div className="exportBoxMas" onClick={addStudentHandler}>
           ADD 
            </div>
          </div>
        </div>{" "}
        <div className="marg-10 d-flex">
          <Space direction="vertical" style={{ width: "400px" }}>
            <Input
             allowClear
              prefix={<BsSearch size={15} color="#7E7E7E" />}
              placeholder="Search by Admission number, Students name..."
              onChange={searchChangeHandler}
            />
          
          </Space>
          <div className="search" onClick={searchHandler}>Search</div>
         

        </div>
        <div className="ErrorMsg" style={{marginTop:'-10px'}}>{error}</div>
        {filtShow?
        <div className="d-flex">
          <div>
            <h5 className="selectHead">Class</h5>
            <Select
              defaultValue="Class"
              style={{
                width: 120,
              }}
              onChange={classChange}
              options={classArray}
            />
          </div>
          <div  style={{marginLeft:'20px'}}>
            <h5 className="selectHead">Route</h5>
            <Select
              defaultValue="Route"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={routeArray}
            />
          </div>
          <div  style={{marginLeft:'20px'}}>
            <h5 className="selectHead">Status</h5>
            <Select
              defaultValue="Status"
              style={{
                width: 120,
              }}
              onChange={statusChange}
              options={[{
                value: true,
                label: 'Enabled',
              },{
                value:false,
                label:'Disabled'
              }]}
            />
          </div>
          {/* <div style={{marginLeft:'20px'}}>
                        <h5 className='selectHead'>Start date</h5>
                        <DatePicker onChange={onStartDateChange} />
                    </div>
                    <div style={{marginLeft:'20px'}}>
                        <h5 className='selectHead'>End date</h5>
                        <DatePicker onChange={onEndDateChange} />
                    </div> */}
        </div>
        :null}
      </div>
      {students}
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        marginPagesDisplayed={3}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        containerClassName={'pagination justify-content-end pageout'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </DashboardLayout>
  );
};
export default MasterStudentList;
